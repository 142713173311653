import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./news-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TextEditor from "../../common/text-editor";
import apiEnvironment from "../../../../api/api-config";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { CONSTANT } from "../../../../constants/constant";

function NewsModal(props) {
  const [labelFile, setLabelFile] = useState("Chọn Ảnh...");
  const [file, setFile] = useState(null);
  const [
    newsCategoryAutoCompleteSelected,
    setNewsCategoryAutoCompleteSelected,
  ] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [isHot, setIsHot] = useState(true);
  const [isFeature, setIsFeature] = useState(true);
  const [isHomePage, setIsHomePage] = useState(true);
  const [status, setStatus] = useState(true);
  const [content, setContent] = useState("");
  const [image_Url, setImage_Url] = useState(null);
  const [xmin, setXmin] = useState(null);
  const [type, setType] = useState(null);
  const [isDeleteImage, setIsDeleteImage] = useState(0);
  const [CKEDITOR, setCKEDITOR] = useState(null);

  const urlImg = apiEnvironment.api;

  useEffect(() => {
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      setCategoryId(props.data.dataInput.categoryId);
      setTitle(props.data.dataInput.title);
      setDescription(props.data.dataInput.description);
      setIsHot(props.data.dataInput.isHot);
      setIsFeature(props.data.dataInput.isFeature);
      setIsHomePage(props.data.dataInput.isHomePage);
      setStatus(props.data.dataInput.status);
      setContent(props.data.dataInput.content);
      setImage_Url(props.data.dataInput.image_Url);
      setXmin(props.data.dataInput.xmin);
      setNewsCategoryAutoCompleteSelected(
        props.data.dataInput.newsCategoryAutoCompleteSelected
      );
      setType(true);
    } else {
      setType(false);
    }
  }, [props]);

  const resetState = () => {
    setLabelFile("Chọn Ảnh...");
    setFile(null);
    setNewsCategoryAutoCompleteSelected(null);
    setTitle(null);
    setDescription(null);
    setIsHot(true);
    setIsFeature(true);
    setIsHomePage(true);
    setStatus(true);

    //case absnomal
    setContent("");

    setImage_Url(null);
    setXmin(null);
    setType(null);
    setIsDeleteImage(0);
  };

  const onSave = (event) => {
    event.preventDefault();
    if (!content) {
      ShowNotification(
        CONSTANT.message.warningContent,
        NotificationMessageType.Warning
      );
      CKEDITOR.editing.view.focus();
    } else {
      props.data.handleCloseModal("save", {
        file: file,
        categoryId: categoryId,
        title: title,
        description: description,
        isHot: isHot ? 1 : 0,
        isFeature: isFeature ? 1 : 0,
        isHomePage: isHomePage ? 1 : 0,
        status: status ? 1 : 0,
        content: content,
        type: type,
      });
      resetState();
    }
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  const handleChangeAutocomplete = (event, name, value) => {
    const newsCategoryItem = props.lookupCategory.content.find(
      (itemNewsCategory) => itemNewsCategory.id === value.id
    );
    setNewsCategoryAutoCompleteSelected(newsCategoryItem);
    setCategoryId(value.id);
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hướng dẫn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputPassword1">Danh Mục</label>
            <Autocomplete
              options={props.lookupCategory.content}
              onChange={(event, value) => {
                handleChangeAutocomplete(event, "categoryId", value);
              }}
              value={newsCategoryAutoCompleteSelected}
              getOptionLabel={(option) => option.name}
              style={{}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>

          {image_Url && (
            <div class="form-group">
              <label for="exampleInputEmail1">Ảnh</label>
              <div class="col-auto">
                <img
                  src={urlImg + image_Url}
                  alt=""
                  class="img-max-news-l"
                ></img>
              </div>
            </div>
          )}

          <div class="form-group">
            <label for="exampleInputEmail1">Chọn Ảnh</label>
            <div class="custom-file" id="customFile" lang="es">
              <input
                type="file"
                class="custom-file-input"
                id="exampleInputFile"
                aria-describedby="fileHelp"
                onChange={(e) => {
                  if (e.target.files[0] && e.target.files[0].name) {
                    setLabelFile(e.target.files[0].name);
                    setFile(e.target.files[0]);
                  } else {
                    setLabelFile("Chọn Ảnh...");
                  }
                }}
              />
              <label class="custom-file-label" for="exampleInputFile">
                {labelFile}
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Tiêu Đề</label>
            <input
              required
              name="title"
              type="text"
              class="form-control"
              id="validationDefault01"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Mô tả</label>
            <textarea
              required
              value={description}
              name="description"
              class="form-control"
              id="exampleFormControlTextarea1"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="validationDefault02">Tin Nóng</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isHot}
                onChange={(e) => {
                  setIsHot(!isHot);
                }}
                id="defaultCheck1"
                name="isHot"
              />
            </div>
            <label for="validationDefault02">Tin Nổi Bật</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isFeature}
                onChange={(e) => {
                  setIsFeature(!isFeature);
                }}
                id="defaultCheck1"
                name="isHot"
              />
            </div>
            <label for="validationDefault02">Tin Trang Chủ</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isHomePage}
                onChange={(e) => {
                  setIsHomePage(!isHomePage);
                }}
                id="defaultCheck1"
                name="isHot"
              />
            </div>
            <label for="validationDefault02">Trạng Thái</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={status}
                onChange={(e) => {
                  setStatus(!status);
                }}
                id="defaultCheck1"
                name="isHot"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Nội Dung</label>
            <TextEditor
              value={props.data.dataInput.content}
              onChange={(data) => {
                setContent(data);
              }}
              onInstance={(instance) => {
                setCKEDITOR(instance);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  lookupCategory: state.lookupCategory,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal);
