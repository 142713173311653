import React from "react";
import { Route, Redirect } from "react-router-dom";
import UrlCollect from "./common/url-collect";

export const PrivateRoute = ({
  component: Component,
  layout: Layout,
  roles,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // const currentUser = authenticationService.currentUserValue;
      const currentAdmin = JSON.parse(sessionStorage.getItem("adminpcc"));
      //console.log(currentAdmin);
      if (!currentAdmin) {
        // not logged in so redirect to login page
        return (
          <Redirect
            to={{
              pathname: UrlCollect.AdmLogin,
              state: { returnUrl: props.location.pathname },
            }}
          />
        );
      }
      // check if route is restricted by role
      if (roles && roles.indexOf(currentAdmin.role) === -1) {
        // role not authorised so redirect DashboardAdmin page
        return <Redirect to={{ pathname: UrlCollect.DashboardAdmin }} />;
      }

      // authorised so return component
      return (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);
