import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_PROJECT_OK = "GET_PROJECT_OK";
export const GET_PROJECT_ERR = "GET_PROJECT_ERR";
export const DELETE_PROJECT_OK = "DELETE_PROJECT_OK";
export const DELETE_PROJECT_ERR = "DELETE_PROJECT_ERR";
export const CREATE_PROJECT_ERR = "CREATE_PROJECT_ERR";
export const UPD_PROJECT_ERR = "UPD_PROJECT_ERR";
export const UPD_PROJECT_OK = "UPD_PROJECT_OK";

export const projectAction = {
  getProject,
  deleteProject,
  createProject,
  updProject,
};
function updProjectOk(payload) {
  return { type: UPD_PROJECT_OK, payload };
}
function updProjectErr(payload) {
  return { type: UPD_PROJECT_ERR, payload };
}
function createProjectErr(payload) {
  return { type: CREATE_PROJECT_ERR, payload };
}
function deleteProjectOk(payload) {
  return { type: DELETE_PROJECT_OK, payload };
}
function deleteProjectErr(payload) {
  return { type: DELETE_PROJECT_ERR, payload };
}
function getProjectOk(payload) {
  return { type: GET_PROJECT_OK, payload };
}
function getProjectErr(payload) {
  return { type: GET_PROJECT_ERR, payload };
}

function updProject(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateProjectAdm, data)
      .then((response) => {
        dispatch(updProjectOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updProjectErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createProject(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateProjectAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getProject());
      })
      .catch((error) => {
        dispatch(createProjectErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteProject(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteProjectAdm + data.Id)
      .then((response) => {
        dispatch(deleteProjectOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteProjectErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getProject(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListAllProjectAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getProjectOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getProjectErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
