import { GET_ROLE_LOOKUP_OK, GET_ROLE_LOOKUP_ERR } from "./role.action";

const InitState = {
  error: null,
  content: null,
};

export default function RoleLookupReducer(state = InitState, action) {
  switch (action.type) {
    case GET_ROLE_LOOKUP_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_ROLE_LOOKUP_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }

    default:
      return state;
  }
}
