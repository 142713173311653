import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const UPDATE_SECURITY_MATRIX_OK = "UPDATE_SECURITY_MATRIX_OK";
export const UPDATE_SECURITY_MATRIX_ERR = "UPDATE_SECURITY_MATRIX_ERR";

export const updateSecurityMatrixAction = {
  updateSecurityMatrix,
};

function updateSecurityMatrixOk(payload) {
  return { type: UPDATE_SECURITY_MATRIX_OK, payload };
}
function updateSecurityMatrixErr(payload) {
  return { type: UPDATE_SECURITY_MATRIX_ERR, payload };
}

function updateSecurityMatrix(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateSecurityMatrix, data)
      .then((response) => {
        dispatch(updateSecurityMatrixOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updateSecurityMatrixErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
