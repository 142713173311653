import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import history from "../../../../common/history";
import UrlCollect from "../../../../common/url-collect";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

//export const LOGIN = "LOGIN";
export const LOGIN_OK = "LOGIN_OK";
export const LOGIN_ERR = "LOGIN_ERR";
export const LOGIN_OUT = "LOGIN_OUT";

export const accountAdminAction = {
  login,
  logout,
};

function loginOk(payload) {
  return { type: LOGIN_OK, payload };
}
function loginErr(payload) {
  return { type: LOGIN_ERR, payload };
}
function loginOut() {
  return { type: LOGIN_OUT };
}

function login(data, returnUrl) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.LoginAdm, data)
      .then((response) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const obj = {
          token: response.content.token,
          email: data.email,
          role: "admin",
        };
        dispatch(loginOk(obj));
        dispatch(RemoveOneSlotToLoadingQueue);
        sessionStorage.setItem("adminpcc", JSON.stringify(obj));
        if (returnUrl) {
          history.push(returnUrl);
        } else {
          history.push(UrlCollect.DashboardAdmin);
        }
      })
      .catch((error) => {
        dispatch(loginErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function logout() {
  return function (dispatch) {
    sessionStorage.removeItem("adminpcc");
    dispatch(loginOut());
    history.push(UrlCollect.AdmLogin);
  };
}
