/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import { CONSTANT } from "../../../constants/constant";
import "./change-password.scss";

const service = new Service();

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passCurrent: null,
      passNew: null,
      passRe: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {}
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    //check pass
    if (this.state.passNew === this.state.passRe) {
      const data = {
        currentPassword: this.state.passCurrent,
        newPassword: this.state.passNew,
      };
      this.props.ShowLoading();
      service
        .post(ApiUrl.ChangePassword, data)
        .then((response) => {
          this.props.HiddenLoading();
          ShowNotification(
            CONSTANT.message.changePassOk,
            NotificationMessageType.Success
          );
        })
        .catch((error) => {
          this.props.HiddenLoading();
          if (error && error.errorMessage) {
            ShowNotification(error.errorMessage, NotificationMessageType.Error);
          } else {
            ShowNotification(
              CONSTANT.message.error_unknown,
              NotificationMessageType.Error
            );
          }
        });
    } else {
      ShowNotification(
        CONSTANT.message.passNotMatch,
        NotificationMessageType.Warning
      );
      this.inputPassRe.focus();
    }
  }
  render() {
    return (
      <div class="layout-container pd15 weblinkl">
        {/**Row 1 */}
        <form onSubmit={this.handleSubmit}>
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton justify-content-center">
            Đổi mật khẩu
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3 justify-content-center">
              <div class="col-6 pl-3">
                <label for="validationDefault01">Mật khẩu hiện tại</label>
                <input
                  name="passCurrent"
                  type="password"
                  class="form-control"
                  value={this.state.passCurrent}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div class="form-row w-100 pt-3 justify-content-center">
              <div class="col-6 pl-3">
                <label for="validationDefault01">Mật khẩu mới</label>
                <input
                  name="passNew"
                  type="password"
                  class="form-control"
                  value={this.state.passNew}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div class="form-row w-100 pt-3 justify-content-center">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Nhập lại mật khẩu mới</label>
                <input
                  ref={(input) => {
                    this.inputPassRe = input;
                  }}
                  name="passRe"
                  type="password"
                  class="form-control"
                  value={this.state.passRe}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div class="row no-gutters vertical-center mg-botton2">
            <div class="col d-flex justify-content-center">
              <button class="btn btn-primary mr-2" name="update" type="submit">
                Cập Nhật
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
