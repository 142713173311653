import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./layout-admin.scss";

class LayoutAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //bodyHeight: null,
    };
  }
  componentDidMount() {}

  render() {
    return (
      <div class="row no-gutters content-admin">
        <div class="content-admin">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdminView);
