/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Service from "../../../api/api-service";
import { CONSTANT } from "../../../constants/constant";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { ApiUrl } from "../../../api/api-url";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import "./dashboard-admin.scss";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

//import { Bar } from "react-chartjs-2";
//import "chartjs-plugin-datalabels";

const service = new Service();
let optionYear = [];
let optionMonth = [];
let year = new Date().getFullYear();
let month = new Date().getMonth() + 1;
for (let i = 0; i < 12; i++) {
  if (i + 1 === month) {
    optionMonth.push(
      <option selected value={i + 1}>
        Tháng {i + 1}
      </option>
    );
  } else {
    optionMonth.push(<option value={i + 1}>Tháng {i + 1}</option>);
  }
}
for (let i = year; i > year - 10; i--) {
  if (i === year) {
    optionYear.push(
      <option selected value={i}>
        Năm {i}
      </option>
    );
  } else {
    optionYear.push(<option value={i}>Năm {i}</option>);
  }
}

class DashboardAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataReport: null,
      optionChartEvaluateProcessing: null,
      optionChartEvaluateTypeSumarize: null,
    };
    this.fetchData = this.fetchData.bind(this);
    this.fetchEvaluateProcessing = this.fetchEvaluateProcessing.bind(this);
    this.fetchEvaluateTypeSumarize = this.fetchEvaluateTypeSumarize.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }
  componentDidMount() {
    this.fetchData();
    this.fetchEvaluateProcessing();
    this.fetchEvaluateTypeSumarize(month, year);
  }
  onChangeSelect(event, type) {
    if (type === "month") {
      month = event.target.value;
    } else {
      year = event.target.value;
    }
    this.fetchEvaluateTypeSumarize(month, year);
  }
  fetchData() {
    this.props.ShowLoading();
    service
      .post(ApiUrl.SumaryRecords)
      .then((response) => {
        this.setState({
          ...this.state,
          dataReport: response.content,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  fetchEvaluateProcessing() {
    this.props.ShowLoading();
    service
      .get(ApiUrl.EvaluateProcessing)
      .then((response) => {
        this.setState({
          ...this.state,
          optionChartEvaluateProcessing: response.content,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  fetchEvaluateTypeSumarize(month, year) {
    this.props.ShowLoading();
    service
      .get(ApiUrl.EvaluateTypeSumarize + `?month=${month}&year=${year}`)
      .then((response) => {
        this.setState({
          ...this.state,
          optionChartEvaluateTypeSumarize: response.content,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  render() {
    let elementArrReport = [];
    const arrayColor = [
      "bg-primary",
      "bg-success",
      "bg-danger",
      "bg-warning",
      "bg-info",
    ];
    let i = 0;
    if (this.state.dataReport) {
      this.state.dataReport.sumaryRecords.map((item, index) => {
        let urlGoto = "";
        if (i === arrayColor.length) {
          i = 0;
        }
        switch (item.status) {
          case 1:
            urlGoto = UrlCollect.DocumentWait;
            break;
          case 98:
            urlGoto = UrlCollect.DocumentNew;
            break;
          case 99:
            urlGoto = UrlCollect.DocumentProcess;
            break;
          case 1000:
            urlGoto = UrlCollect.DocumentEval;
            break;
          default:
            break;
        }
        elementArrReport.push(
          <div
            class="col-md-3 cursor"
            onClick={() => {
              history.push({
                pathname: urlGoto,
              });
            }}
          >
            <div class={"row text-white margin-1 " + arrayColor[i]}>
              <div class="col-8 h-100">
                <div class="row no-gutters font-s40 pdtopleft">
                  {item.count}
                </div>
                <div class="row no-gutters font-s20 pdbottomleft">
                  {item.statusName}
                </div>
              </div>
              <div class="col-4">
                <div class="row no-gutters vertical-center h-100 float-right">
                  <svg
                    width="35"
                    height="35"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="arrow-right"
                    class="svg-inline--fa fa-arrow-right fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        );
        i++;
      });
    }
    return (
      <div class="layout-container pd15 dashboard-admin">
        <div class="row mg40bt">{elementArrReport}</div>

        {/*row 2*/}
        <div class="row no-gutters mg40bt">
          <div class="col bg-white">
            <div class="h50 font-s20 title-char1 pl-1">
              Tình hình tiếp nhận và xử lý hồ sơ
            </div>
            <div class="char1 chart-container pb-2">
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.optionChartEvaluateProcessing}
              />
            </div>
          </div>
        </div>
        {/*end row 2*/}

        {/*row 3*/}
        <div class="row no-gutters">
          <div class="col-12 bg-white">
            <div class="row no-gutters">
              <div class="col-4 title-char1">
                <div class="h50 font-s20 v-center pl-1">
                  Thống kê theo loại hồ sơ
                </div>
              </div>
              <div class="col-8 d-flex justify-content-end title-char1">
                <select
                  class="form-control w-sel pl-1"
                  onChange={(e) => {
                    this.onChangeSelect(e, "month");
                  }}
                >
                  {optionMonth}
                </select>
                <select
                  class="form-control w-sel ml-1 mr-1"
                  onChange={(e) => {
                    this.onChangeSelect(e, "year");
                  }}
                >
                  {optionYear}
                </select>
              </div>
            </div>
            <div class="char1 chart-container">
              <HighchartsReact
                highcharts={Highcharts}
                options={this.state.optionChartEvaluateTypeSumarize}
              />
            </div>
          </div>
        </div>
        {/*end row 3*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  weblink: state.weblink,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
