import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./manager-comment-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function ManagerCommentModal(props) {
  const [content, setContent] = useState("");
  useEffect(() => {
    setContent(props.data.content);
  }, [props.data.content]);

  const onSave = () => {
    props.data.handleCloseModal("save-manager", { data: content });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-manager", { data: content });
    resetState();
  };

  const resetState = () => {
    setContent("");
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cập Nhập Ý Kiến</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={content}
          onChange={(event) => {
            setContent(event.target.value);
          }}
          rows="5"
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={onSave}>
          Lưu Thay Đổi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerCommentModal);
