import React from "react";
import HeaderAdmin from "../admin/header/header-admin";
import FooterAdminView from "../admin/footer/footer-admin";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";
import Sidebar from "../admin/sidebar/sidebar-admin";
import {
  Button,
  Navbar,
  Nav,
  Form,
  FormControl,
  Modal,
  Container,
  Row,
  Col,
  NavDropdown,
  Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./layout-admin.scss";

class LayoutAdminLoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyHeight: null,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div class="row no-gutters content-admin">
        <div class="content-admin" style={this.state.bodyHeight}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutAdminLoginView);
