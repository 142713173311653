import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./forgot-password.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function ForgotPasswordModal(props) {
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  const onSave = (e) => {
    e.preventDefault();
    props.data.handleCloseModal("save-forgot", {
      email: email,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-forgot", {});
    resetState();
  };

  const resetState = () => {
    setEmail("");
  };

  return (
    <Modal centered show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Quên mật khẩu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputEmail1">Nhập Email</label>
            <input
              required
              name="title"
              type="email"
              class="form-control"
              id="validationDefault01"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <button type="submit" class="btn btn-primary">
            Gửi
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
