import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const HELPING_ADD_RECORD_OK = "HELPING_ADD_RECORD_OK";
export const HELPING_ADD_RECORD_ERR = "HELPING_ADD_RECORD_ERR";

export const helpingAddRecordAction = {
  getHelpingAddRecord,
};

function getHelpingAddRecordOk(payload) {
  return { type: HELPING_ADD_RECORD_OK, payload };
}
function getHelpingAddRecordErr(payload) {
  return { type: HELPING_ADD_RECORD_ERR, payload };
}

function getHelpingAddRecord(documentId, documentTypeId, callback) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(
        ApiUrl.GetTemplateHelpingAddRecords +
          "?documentId=" +
          documentId +
          "&documentTypeId=" +
          documentTypeId
      )
      .then((response) => {
        dispatch(getHelpingAddRecordOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
        callback();
      })
      .catch((error) => {
        dispatch(getHelpingAddRecordErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
