/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { communeAction } from "../../../redux/store/admin/commune/commune.action";
import { lookupDistrictAction } from "../../../redux/store/admin/lookup-district/lookup-district.action";
import { lookupProvinceAction } from "../../../redux/store/admin/lookup-province/lookup-province.action";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import DeleteDialog from "../modal/dialog-delete/dialog-delete.view";
import CommuneModal from "../modal/config/commune-modal/commune-modal";
import "./commune.scss";

const pagination = paginationFactory({
  sizePerPage: 15,
  paginationSize: 10,
  alwaysShowAllBtns: true,
  hideSizePerPage: true,
});
let dataInput = {};

class District extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isOpenDelModal: false,
      rowId: null,
      communeShow: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    this.props.getLookupProvince();
    this.props.getLookupDistrict();
    this.props.getCommune();
  }
  handleShowModal() {
    dataInput["type"] = false;
    this.setState({ communeShow: true });
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close":
        this.setState({ communeShow: false });
        break;
      case "save":
        if (data.type) {
          this.props.updCommune({
            id: this.state.id,
            name: data.name,
            administrativeUnitCode: 0,
            districtId: data.districtId,
            districtName: data.districtName,
            provinceId: data.provinceId,
            provinceName: data.provinceName,
          });
          this.setState({ communeShow: false });
        } else {
          this.props.createCommune({
            name: data.name,
            administrativeUnitCode: 0,
            districtId: data.districtId,
          });
          this.setState({ communeShow: false });
        }
        break;
      default:
        break;
    }
  }
  handleDelete() {
    this.props.deleteCommune({ Id: this.state.rowId });
  }
  render() {
    const columns = [
      {
        dataField: "name",
        text: "Tên",
      },
      {
        dataField: "districtName",
        text: "Quận/ Huyện",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      {
        dataField: "provinceName",
        text: "Tỉnh/ Thành Phố",
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      {
        dataField: "id",
        text: "Xóa",
        headerStyle: (colum, colIndex) => {
          return { width: "45px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  ...this.state,
                  isOpenDelModal: true,
                  rowId: row.id,
                });
                /*
                if (window.confirm(CONSTANT.message.confirmDelete))
                  this.handleDelete(e, row.id);
                */
              }}
            >
              <svg
                width="18"
                height="18"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash-alt"
                class="svg-inline--fa fa-trash-alt fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="red"
                  d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                ></path>
              </svg>
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        dataInput["name"] = row.name;
        dataInput["provinceId"] = row.provinceId;
        dataInput["districtId"] = row.districtId;
        dataInput["type"] = true;
        this.setState({
          ...this.state,
          id: row.id,
          communeShow: true,
        });
      },
    };
    const products = [];
    if (
      this.props.provinceLookup.content &&
      this.props.districtLookup.content &&
      this.props.commune.content &&
      this.props.commune.content.items.length > 0
    ) {
      this.props.commune.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <div class="layout-container pd15 district-adm">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
          Phường Xã
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              classes="table-striped"
              rowClasses="mytable-hover-row"
            />
          </div>
          <div class="row no-gutters vertical-center mg-botton2">
            <div class="col d-flex justify-content-center">
              <button
                class="btn btn-primary"
                name="create"
                type="submit"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                Tạo Mới
              </button>
            </div>
          </div>
        </div>
        <DeleteDialog
          isOpen={this.state.isOpenDelModal}
          onClose={() => {
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
          onSuccess={() => {
            this.handleDelete();
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
        ></DeleteDialog>
        <CommuneModal
          data={{
            show: this.state.communeShow,
            handleCloseModal: this.handleCloseModal,
            dataInput: dataInput,
          }}
        ></CommuneModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  commune: state.communeAdm,
  provinceLookup: state.lookupProvince,
  districtLookup: state.lookupDistric,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCommune: communeAction.getCommune,
      deleteCommune: communeAction.deleteCommune,
      createCommune: communeAction.createCommune,
      updCommune: communeAction.updCommune,
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
      getLookupDistrict: lookupDistrictAction.getLookupDistrict,
      getLookupProvince: lookupProvinceAction.getLookupProvince,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(District);
