/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import { userAction } from "../../../redux/store/admin/user/user.action";
import { helpingAddRecordAction } from "../../../redux/store/admin/helping-add-record/helping-add-record.action";
import DateFormat from "dateformat";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import apiEnvironment from "../../../api/api-config";
import AssignUserModal from "../modal/assign-modal/assign-user-modal";
import ManagerCommentModal from "../modal/manager-comment-modal/manager-comment-modal";
import LeaderCommentModal from "../modal/leader-comment-modal/leader-comment-modal";
import HelpingAddRecord from "../modal/helping-add-record/helping-add-record";
import RecordModal from "../modal/record-modal/record-modal";
import ConfirmModalView from "../modal/confirm-modal/confirm-modal";
import { CONSTANT } from "../../../constants/constant";
import * as configuration from "../../../utils/configuration";
import "react-datepicker/dist/react-datepicker.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "./document-new-detail.scss";
var FileSaver = require("file-saver");

const service = new Service();
let urlBack = "";
let screenName = "";

class DocumentNewDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //iddoc: props.location.state ? props.location.state.iddoc : null,
      iddoc: this.props.match.params ? this.props.match.params.id : null,
      //screenName: props.location.state ? props.location.state.screenName : "",
      //titleName: props.location.state ? props.location.state.titleName : "",
      //urlParent: props.location.state ? props.location.state.urlParent : "",
      startDate: new Date(),
      btnClick: null,
      //modal
      assignUserProcessShow: false,
      managerShow: false,
      leaderShow: false,
      recordShow: false,
      typeRecord: null,
      helpingAddRecordShow: null,
      isOpenConfirmModal: false,
      titleRecord: "",
      //data
      company: {
        name: null,
        taxCode: null,
        address: null,
        phoneNumber: null,
        faxNumber: null,
        email: null,
        provinceId: null,
        provinceName: null,
        districtId: null,
        districtName: null,
        communeId: null,
        communeName: null,
        created_by: null,
        created_date: null,
        modified_by: null,
        modified_date: null,
        id: null,
      },
      sentUser: {
        id: null,
        email: null,
        fullName: null,
        identityId: null,
        identityDate: null,
        identityBy: null,
        dateOfBirth: null,
        sex: null,
        address: null,
        createdDate: null,
        modifiedDate: null,
        createdBy: null,
        modifiedBy: null,
        avatar: null,
        status: null,
        description: null,
        roleName: null,
        phoneNumber: null,
        companyName: null,
      },
      resultFile: null,
      listDocFile: null,
      projectName: null,
      name: null,
      evaluateRecordsTypeName: null,
      address: null,
      acreage: null,
      totalInvestment: null,
      feesRate: null,
      fees: null,
      workflows: null,
      leaderOpinions: null,
      currentStep: null,
      status: null,
      id: null,
      documentTypeId: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.btnSbtClick = this.btnSbtClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.assignUserProcessSave = this.assignUserProcessSave.bind(this);
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
    this.addLeaderOpinion = this.addLeaderOpinion.bind(this);
    this.addUserOpinion = this.addUserOpinion.bind(this);
    this.getEvaluateRecordsByID = this.getEvaluateRecordsByID.bind(this);
    this.updateResultFile = this.updateResultFile.bind(this);
    this.updateHelpingAddRecords = this.updateHelpingAddRecords.bind(this);
    this.handleAcceptModalConfirm = this.handleAcceptModalConfirm.bind(this);
  }
  componentDidMount() {
    let urlCheck = "";
    if (this.props.location.pathname) {
      let idx = this.props.location.pathname.lastIndexOf("/");
      urlCheck = this.props.location.pathname.substring(0, idx);
    }
    switch (urlCheck) {
      case UrlCollect.DocumentWaitDetail:
        screenName = CONSTANT.label.titleDocumentWait;
        urlBack = UrlCollect.DocumentWait;
        break;
      case UrlCollect.DocumentNewDetail:
        screenName = CONSTANT.label.titleDocumentNew;
        urlBack = UrlCollect.DocumentNew;
        break;
      case UrlCollect.DocumentProcessDetail:
        screenName = CONSTANT.label.titleDocumentProcess;
        urlBack = UrlCollect.DocumentProcess;
        break;
      case UrlCollect.DocumentRegDetail:
        screenName = CONSTANT.label.titleDocumentReg;
        urlBack = UrlCollect.DocumentReg;
        break;
      case UrlCollect.DocumentEvalDetail:
        screenName = CONSTANT.label.titleDocumentEval;
        urlBack = UrlCollect.DocumentEval;
        break;
      default:
        break;
    }
    this.getEvaluateRecordsByID();
  }
  updateHelpingAddRecords(data) {
    let dataInput = {
      id: this.props.helpingAddRecord.content.id, // not edit
      name: this.props.helpingAddRecord.content.name, // not edit
      content: data.data,
      documentId: this.state.id, // not edit
      documentTypeId: this.state.documentTypeId, // not edit
      code: this.props.helpingAddRecord.content.code, // not edit
    };
    this.props.ShowLoading();
    service
      .post(ApiUrl.UpdateHelpingAddRecords, dataInput)
      .then((response) => {
        this.props.HiddenLoading();
        //this.getEvaluateRecordsByID();
        this.setState({ helpingAddRecordShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  updateResultFile(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.UpdateResultFile, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ recordShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  getEvaluateRecordsByID() {
    this.props.ShowLoading();
    service
      .get(ApiUrl.GetEvaluateRecordsByID + this.state.iddoc)
      .then((response) => {
        if (response && response.content) {
          this.setState({
            ...this.state,
            company: response.content.company,
            sentUser: response.content.sentUser
              ? response.content.sentUser
              : this.state.sentUser,
            resultFile: response.content.resultFile,
            listDocFile: response.content.listFile,
            projectName: response.content.projectName,
            name: response.content.name,
            evaluateRecordsTypeName: response.content.evaluateRecordsTypeName,
            address: response.content.address,
            acreage: response.content.acreage,
            totalInvestment: response.content.totalInvestment,
            feesRate: response.content.feesRate,
            fees: response.content.fees,
            workflows: response.content.workflows,
            leaderOpinions: response.content.leaderOpinions,
            currentStep: response.content.currentStep,
            status: response.content.status,
            id: response.content.id,
            documentTypeId: response.content.documentTypeId,
          });
        }
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  addUserOpinion(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.AddUserOpinion, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ leaderShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  addLeaderOpinion(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.AddLeaderOpinion, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.getEvaluateRecordsByID();
        this.setState({ managerShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  reject(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.Reject, data)
      .then((response) => {
        this.props.HiddenLoading();
        history.push({
          pathname: urlBack,
        });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  approve(data) {
    this.props.ShowLoading();
    service
      .post(ApiUrl.Approve, data)
      .then((response) => {
        this.props.HiddenLoading();
        history.push({
          pathname: urlBack,
        });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  assignUserProcessSave(arrayUserId) {
    const data = {
      documentId: this.state.id,
      currentStep: this.state.currentStep.id,
      documentTypeId: this.state.documentTypeId,
      userIds: arrayUserId,
    };
    this.props.ShowLoading();
    service
      .post(ApiUrl.AssignUserProcessSave, data)
      .then((response) => {
        this.props.HiddenLoading();
        this.setState({ assignUserProcessShow: false });
      })
      .catch((error) => {
        this.props.HiddenLoading();
        this.setState({ assignUserProcessShow: false });
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  handleShowModal(actionType) {
    if (actionType === "ASSIGNUSERPROCESS") {
      this.props.getUserByDeparment(() => {
        this.setState({ assignUserProcessShow: true });
      });
    } else if (actionType === "HELPINGADDRECORDS") {
      this.props.getHelpingAddRecord(
        this.state.id,
        this.state.documentTypeId,
        () => {
          this.setState({ helpingAddRecordShow: true });
        }
      );
    } else if (actionType === "UPDATERESULT") {
      this.setState({
        recordShow: true,
        typeRecord: "UPDATERESULT",
        titleRecord: "Cập Nhập Kết Quả",
      });
    } else if (actionType === "CANCELRECORDS") {
      this.setState({
        recordShow: true,
        typeRecord: "CANCELRECORDS",
        titleRecord: "Hủy Kết Quả",
      });
    } else if (actionType === "updateComemntManagerModal") {
      this.setState({ managerShow: true });
    } else if (actionType === "updateComemntLeaderModal") {
      this.setState({ leaderShow: true });
    }
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close-assign":
        this.setState({ assignUserProcessShow: false });
        break;
      case "save-assign":
        this.assignUserProcessSave(data.data);
        break;
      case "close-manager":
        this.setState({ managerShow: false });
        break;
      case "save-manager":
        const user = configuration.getUserInfo2();
        const dataInput = {
          documentId: this.state.id,
          documentTypeId: this.state.documentTypeId,
          content: data.data,
          leaderUserId: user.userId,
        };
        this.addLeaderOpinion(dataInput);
        break;
      case "close-helping":
        this.setState({ helpingAddRecordShow: false });
        break;
      case "save-helping":
        this.updateHelpingAddRecords(data);
        break;
      case "close-leader":
        this.setState({ leaderShow: false });
        break;
      case "save-leader":
        const formData = new FormData();
        formData.append("WorkFlowId", this.state.currentStep.id);
        formData.append("Description", data.description);
        formData.append("IsNoCertification", data.isNoCertification);
        if (data.fileList.length > 0) {
          for (let i = 0; i < data.fileList.length; i++) {
            formData.append("files", data.fileList[i]);
          }
        }
        this.addUserOpinion(formData);
        break;
      case "close-record":
        this.setState({ recordShow: false });
        break;
      case "save-record":
        const dataIp = new FormData();
        if (this.state.typeRecord === "UPDATERESULT") {
          if (
            this.state.currentStep &&
            this.state.currentStep.isNoCertification
          ) {
            dataIp.append("WorkFlowId", this.state.currentStep.id);
            dataIp.append("Description", data.description);
            dataIp.append("ElementType", 2);
            dataIp.append(
              "ElementName",
              "Quyết định không cấp giấy chứng nhận"
            );
            dataIp.append("DocumentId", this.state.id);
            dataIp.append("file", data.file);
          } else {
            dataIp.append("WorkFlowId", this.state.currentStep.id);
            dataIp.append("Description", data.description);
            dataIp.append("ElementType", 1);
            dataIp.append("ElementName", "Giấy chứng nhận");
            dataIp.append("DocumentId", this.state.id);
            dataIp.append("RecordsNo", data.recordsNo);
            dataIp.append("file", data.file);
          }
        } else if (this.state.typeRecord === "CANCELRECORDS") {
          dataIp.append("WorkFlowId", this.state.currentStep.id);
          dataIp.append("ElementType", 4);
          dataIp.append("ElementName", "Quyết định hủy kết quả");
          dataIp.append("DocumentId", this.state.id);
          dataIp.append("file", data.file);
        }
        this.updateResultFile(dataIp);
        break;
      default:
        break;
    }
  }
  btnSbtClick(btnName) {
    this.setState({
      ...this.state,
      btnClick: btnName,
    });
  }
  handleAcceptModalConfirm() {
    if (this.state.btnClick === "REJECT") {
      const data = {
        currentWorkflowId: this.state.currentStep.id,
        documentId: this.state.id,
        documentTypeId: this.state.documentTypeId,
      };
      this.reject(data);
    } else if (this.state.btnClick === "APPROVAL") {
      const data = {
        currentWorkflowId: this.state.currentStep.id,
        documentId: this.state.id,
        documentTypeId: this.state.documentTypeId,
      };
      this.approve(data);
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.btnClick === "BACK") {
      history.push({
        pathname: urlBack,
      });
    } else if (this.state.btnClick === "REJECT") {
      this.setState({
        ...this.state,
        isOpenConfirmModal: true,
      });
    } else if (this.state.btnClick === "APPROVAL") {
      this.setState({
        ...this.state,
        isOpenConfirmModal: true,
      });
    }
  }
  setStartDate(date) {
    this.setState({
      ...this.state,
      startDate: date,
    });
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const iconRight = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-right"
        class="svg-inline--fa fa-caret-right fa-w-6"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 512"
      >
        <path
          fill="black"
          d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
        ></path>
      </svg>
    );

    const iconDownload = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="download"
        class="svg-inline--fa fa-download fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
        ></path>
      </svg>
    );

    const iconView = (
      <svg
        width="14"
        height="14"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="eye"
        class="svg-inline--fa fa-eye fa-w-18"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
        ></path>
      </svg>
    );

    const leaderCommentItems = [];
    const managerCommentItems = [];
    const btnList = [];
    let isShowBtnCommentLeader = false;
    let isShowBtnCommentManager = false;
    let rowResultFile = [];
    let rowlistDocFile = [];

    //render tableDocFile
    if (this.state.listDocFile && this.state.listDocFile.length > 0) {
      this.state.listDocFile.map((item, index) => {
        let nameTb = item.recordElement ? item.recordElement.name : "";
        let fileName = item.file ? item.file.fileName : "";
        let filePreview = item.file ? item.file.filePreview : "";
        rowlistDocFile.push(
          <tr class="d-flex">
            <th class="col-1">{index}</th>
            <td class="col-5">{nameTb}</td>
            <td class="clTitleSup col-4">{fileName}</td>
            <td class="text-center col-1">
              <a
                href="#"
                onClick={() => {
                  window.open(apiEnvironment.api + filePreview, "_blank");
                }}
              >
                {iconView}
              </a>
            </td>
            <td class="text-center col-1">
              <a
                href="#"
                onClick={() => {
                  FileSaver.saveAs(apiEnvironment.api + filePreview, fileName);
                }}
              >
                {iconDownload}
              </a>
            </td>
          </tr>
        );
      });
    }

    //render tableResultFile
    if (this.state.resultFile && this.state.resultFile.length > 0) {
      this.state.resultFile.map((item, index) => {
        let fileName = item.file ? item.file.fileName : "";
        let filePreview = item.file ? item.file.filePreview : "";
        rowResultFile.push(
          <tr class="d-flex">
            <th class="col-1">{index}</th>
            <td class="clTitleSup col-10">{fileName}</td>
            <td class="text-center col-1">
              <a
                href="#"
                onClick={() => {
                  FileSaver.saveAs(apiEnvironment.api + filePreview, fileName);
                }}
              >
                {iconDownload}
              </a>
            </td>
          </tr>
        );
      });
    }

    //check show/hide btn CommentManager
    if (this.state.status < 1000) {
      isShowBtnCommentManager = true;
    }

    //list btn
    btnList.push(
      <button
        class="btn btn-secondary mr-2"
        type="submit"
        name="BACK"
        onClick={() => {
          this.btnSbtClick("BACK");
        }}
      >
        Quay Lại
      </button>
    );
    if (
      this.state.currentStep &&
      this.state.currentStep.stepActions &&
      this.state.currentStep.stepActions.length > 0
    ) {
      let isShowBtnApproval = false;
      let isShowBtnReject = false;
      this.state.currentStep.stepActions.map((item, index) => {
        if (item.action === "REJECT") {
          isShowBtnReject = true;
        }
        if (item.action === "APPROVAL") {
          isShowBtnApproval = true;
        }

        if (
          item.action === "ASSIGNUSERPROCESS" ||
          item.action === "HELPINGADDRECORDS" ||
          item.action === "UPDATERESULT" ||
          item.action === "CANCELRECORDS"
        ) {
          btnList.push(
            <button
              class="btn btn-primary mr-2"
              type="button"
              name={item.action}
              onClick={() => {
                this.handleShowModal(item.action);
              }}
            >
              {item.buttonLabel}
            </button>
          );
        } else if (item.action === "REJECT") {
          btnList.push(
            <button
              class="btn btn-warning mr-2"
              type="submit"
              name={item.action}
              onClick={() => {
                this.btnSbtClick(item.action);
              }}
            >
              {item.buttonLabel}
            </button>
          );
        } else {
          btnList.push(
            <button
              class="btn btn-primary mr-2"
              type="submit"
              name={item.action}
              onClick={() => {
                this.btnSbtClick(item.action);
              }}
            >
              {item.buttonLabel}
            </button>
          );
        }
      });

      if (isShowBtnApproval && isShowBtnReject) {
        isShowBtnCommentLeader = true;
      }
    }
    //end list btn

    //list comment leader
    if (this.state.workflows && this.state.workflows.length > 0) {
      this.state.workflows.map((item, index) => {
        const listFiles = [];
        if (item.listFile && item.listFile.length > 0) {
          item.listFile.map((itemF, indexF) => {
            listFiles.push(
              <div
                class="pl-3 w-100"
                onClick={() => {
                  const urlF = itemF.filePreview;
                  window.open(apiEnvironment.api + urlF, "_blank");
                }}
              >
                <a href="#">
                  {" "}
                  {iconRight} <span class="clTitleSup">{itemF.fileName}</span>
                </a>
              </div>
            );
          });
        }
        leaderCommentItems.push(
          <div class="row no-gutters vertical-center mb-4" key={index}>
            <div class="name-approve">{item.userApprovalFullName} - </div>{" "}
            <div class="date-approve">
              {item.statusName}{" "}
              {DateFormat(item.approvalDate, "dd/mm/yyyy HH:MM")}
            </div>
            {item.description && (
              <div class="pl-2 w-100">
                {" "}
                {iconRight} {item.description}
              </div>
            )}
            {listFiles.length > 0 && <div class="pl-2 w-100"> {listFiles}</div>}
          </div>
        );
      });
    }
    //end list comment leader

    //list comment manager
    if (this.state.leaderOpinions && this.state.leaderOpinions.length > 0) {
      this.state.leaderOpinions.map((item, index) => {
        managerCommentItems.push(
          <div class="row no-gutters vertical-center mb-4" key={index}>
            <div class="name-approve">{item.leaderFullName} - </div>{" "}
            <div class="date-approve">
              {DateFormat(item.opinionDate, "dd/mm/yyyy HH:MM")}
            </div>
            <div class="pl-2 w-100">
              {" "}
              {iconRight} {item.content}
            </div>
          </div>
        );
      });
    }
    //end list comment manager

    return (
      <div class="layout-container pd15 document-new-detail">
        <form onSubmit={this.handleSubmit}>
          {/*row1*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            {screenName} - {this.state.name}
          </div>
          <div class="row no-gutters vertical-center h-50-px bg-white v-center mg-botton2">
            {this.state.projectName}
          </div>

          {/*row2*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin chủ đầu tư
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Tên công ty</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.name}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Mã số thuế</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.taxCode}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Số điện thoại</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.phoneNumber}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Số Fax</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.faxNumber}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.company.email}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Địa chỉ trụ sở</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.company.address}
                  readOnly
                  required
                />
              </div>
            </div>
          </div>

          {/*row3*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin người được ủy quyền
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Họ và Tên</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.fullName}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Số CMND/CCCD</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.identityId}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Địa chỉ</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.address}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Ngày cấp</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.createdBy}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Số điện thoại</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.sentUser.phoneNumber}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Nơi cấp</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  value={this.state.sentUser.identityBy}
                  readOnly
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  readOnly
                  value={this.state.sentUser.email}
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Quốc tịch</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value="Việt Nam"
                  required
                />
              </div>
            </div>
          </div>

          {/*row4*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Thông tin dự án
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Tên dự án</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.projectName}
                  readOnly
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Danh mục dự án</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={this.state.evaluateRecordsTypeName}
                  required
                />
              </div>
            </div>

            <div class="form-row w-100">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Địa điểm</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  readOnly
                  value={this.state.address}
                  required
                />
              </div>
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault02">Diện tích</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={this.state.acreage}
                  required
                />
              </div>
            </div>
          </div>

          {/*row5*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            <div className="col-6">Hồ sơ thẩm duyệt</div>
            <div className="col-6 pr-2"></div>
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
            <table class="table table-bordered">
              <thead>
                <tr class="d-flex">
                  <th class="col-1">STT</th>
                  <th class="col-5">Tên hồ sơ</th>
                  <th class="col-4">Tập tin</th>
                  <th class="col-1">Xem</th>
                  <th class="col-1">Tải về</th>
                </tr>
              </thead>
              <tbody>{rowlistDocFile}</tbody>
            </table>
          </div>

          {/*row5*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            <div className="col-6">Kết quả thẩm duyệt</div>
            <div className="col-6 pr-2"></div>
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2 pl-3 pt-3 pr-2">
            <table class="table table-bordered">
              <thead>
                <tr class="d-flex">
                  <th class="col-1">STT</th>
                  <th class="col-10">Tập tin</th>
                  <th class="col-1">Tải về</th>
                </tr>
              </thead>
              <tbody>{rowResultFile}</tbody>
            </table>
          </div>

          {/*row6*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Lệ phí thẩm duyệt (VND)
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault01">Tổng mức đầu tư</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  readOnly
                  value={parseFloat(this.state.totalInvestment)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault02">Mức thu</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={parseFloat(this.state.fees)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
              <div class="col-4 mb-3 pl-3">
                <label for="validationDefault02">Lệ phí thu</label>
                <input
                  type="text"
                  class="form-control"
                  id="validationDefault02"
                  readOnly
                  value={parseFloat(this.state.feesRate)
                    //.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  required
                />
              </div>
            </div>
          </div>

          {/*row7*/}

          {/*row8*/}
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            <div class="col-6">Ý kiến lãnh đạo</div>
            <div class="col-6">Ý kiến cán bộ phụ trách</div>
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton">
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3 border-secondary">
                <div class="overflow-auto sc-cm border border-white h-cm">
                  <div class="row no-gutters vertical-center">
                    {managerCommentItems}
                  </div>
                </div>
                {isShowBtnCommentManager && (
                  <button
                    class="btn btn-secondary mt-2 btn-sm"
                    type="button"
                    name="updateComemntManagerModal"
                    onClick={() => {
                      this.handleShowModal("updateComemntManagerModal");
                    }}
                  >
                    Cập nhật ý kiến
                  </button>
                )}
              </div>
              <div class="col-6 mb-3 pl-3">
                <div class="overflow-auto sc-cm border border-white h-cm">
                  <div class="row no-gutters vertical-center">
                    {leaderCommentItems}
                  </div>
                </div>
                {isShowBtnCommentLeader && (
                  <button
                    class="btn btn-secondary mt-2 btn-sm"
                    type="button"
                    name="updateComemntLeaderModal"
                    onClick={() => {
                      this.handleShowModal("updateComemntLeaderModal");
                    }}
                  >
                    Cập nhật ý kiến
                  </button>
                )}
              </div>
            </div>
          </div>

          {/*buton*/}
          <div class="row no-gutters vertical-center mt-4 mg-botton2">
            <div class="col d-flex justify-content-center">{btnList}</div>
          </div>
        </form>
        {/** ====================================================== */}
        {/*updateComemntManagerModal*/}
        <ManagerCommentModal
          data={{
            show: this.state.managerShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></ManagerCommentModal>

        {/*updateComemntLeaderModal*/}
        <LeaderCommentModal
          data={{
            show: this.state.leaderShow,
            currentStep: this.state.currentStep,
            handleCloseModal: this.handleCloseModal,
          }}
        ></LeaderCommentModal>

        {/*HelpingAddRecords*/}
        <HelpingAddRecord
          data={{
            show: this.state.helpingAddRecordShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></HelpingAddRecord>
        <div
          class="modal fade"
          id="updateComemntLeaderModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Cập Nhập Ý Kiến
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"></div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Đóng
                </button>
                <button type="button" class="btn btn-primary">
                  Lưu Thay Đổi
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*ASSIGNUSERPROCESS*/}
        <AssignUserModal
          data={{
            show: this.state.assignUserProcessShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></AssignUserModal>

        {/**Record Modal */}
        <RecordModal
          data={{
            show: this.state.recordShow,
            title: this.state.titleRecord,
            isNoCertification: this.state.currentStep
              ? this.state.currentStep.isNoCertification
              : null,
            typeRecord: this.state.typeRecord,
            handleCloseModal: this.handleCloseModal,
          }}
        ></RecordModal>

        {/**ConfirmModalView */}
        <ConfirmModalView
          open={this.state.isOpenConfirmModal}
          handleClose={() => {
            this.setState({ ...this.state, isOpenConfirmModal: false });
          }}
          handleAccept={() => {
            this.handleAcceptModalConfirm();
            this.setState({ ...this.state, isOpenConfirmModal: false });
          }}
        ></ConfirmModalView>
        {/*end*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //evaluateRecords: state.evaluateRecords,
  helpingAddRecord: state.helpingAddRecord,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
      getUserByDeparment: userAction.getUserByDeparment,
      getHelpingAddRecord: helpingAddRecordAction.getHelpingAddRecord,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DocumentNewDetail);
