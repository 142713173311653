import {
  UPDATE_SECURITY_MATRIX_OK,
  UPDATE_SECURITY_MATRIX_ERR,
} from "./update-security-matrix.action";

const InitState = {
  error: null,
  content: null,
};

export default function UpdateSecurityMatrixReducer(state = InitState, action) {
  switch (action.type) {
    case UPDATE_SECURITY_MATRIX_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case UPDATE_SECURITY_MATRIX_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
