import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_QUALIFY_OK = "GET_QUALIFY_OK";
export const GET_QUALIFY_ERR = "GET_QUALIFY_ERR";
export const DELETE_QUALIFY_OK = "DELETE_QUALIFY_OK";
export const DELETE_QUALIFY_ERR = "DELETE_QUALIFY_ERR";
export const CREATE_QUALIFY_ERR = "CREATE_QUALIFY_ERR";

export const qualifyAction = {
  getQualify,
  deleteQualify,
  createQualify,
  updQualify,
};
function createQualifyErr(payload) {
  return { type: CREATE_QUALIFY_ERR, payload };
}
function deleteQualifyOk(payload) {
  return { type: DELETE_QUALIFY_OK, payload };
}
function deleteQualifyErr(payload) {
  return { type: DELETE_QUALIFY_ERR, payload };
}
function getQualifyOk(payload) {
  return { type: GET_QUALIFY_OK, payload };
}
function getQualifyErr(payload) {
  return { type: GET_QUALIFY_ERR, payload };
}

function updQualify(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.QualifyUpdate, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getQualify());
      })
      .catch((error) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createQualify(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.QualifyCreate, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getQualify());
      })
      .catch((error) => {
        dispatch(createQualifyErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteQualify(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.QualifyDelete + data.Id)
      .then((response) => {
        dispatch(deleteQualifyOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteQualifyErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getQualify(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.QualifyGetListAll + "?PageIndex=1&PageSize=100000")
      .then((response) => {
        dispatch(getQualifyOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getQualifyErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
