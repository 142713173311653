const UrlCollect = {
  //-- Admin
  DashboardAdmin: "/dashboard-admin",

  DocumentWait: "/doc-wait",
  DocumentNew: "/doc-new",
  DocumentProcess: "/doc-process",
  DocumentReg: "/doc-reg",
  DocumentEval: "/doc-eval",

  DocumentWaitDetailPattern: "/doc-wait-detail/:id",
  DocumentNewDetailPattern: "/doc-new-detail/:id",
  DocumentProcessDetailPattern: "/doc-process-detail/:id",
  DocumentRegDetailPattern: "/doc-reg-detail/:id",
  DocumentEvalDetailPattern: "/doc-eval-detail/:id",

  DocumentWaitDetail: "/doc-wait-detail",
  DocumentNewDetail: "/doc-new-detail",
  DocumentProcessDetail: "/doc-process-detail",
  DocumentRegDetail: "/doc-reg-detail",
  DocumentEvalDetail: "/doc-eval-detail",

  AdmLogin: "/",
  ChangePass: "/change-pass",
  Weblink: "/weblink",
  Province: "/province",
  District: "/district",
  Commune: "/commune",
  Project: "/project",
  Department: "/department",
  Position: "/position",
  News: "/news",
  Account: "/account",
  Qualify: "/qualify",
  Delegacy: "/delegacy",
  ManagerUser: "/manager-user",
  Role: "/role",
};

export default UrlCollect;
