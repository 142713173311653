import React from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import "./permission.scss";
import { userManagement } from "../../../../../redux/store/admin/manager-user/manager-user.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";
import { lookupScreenAction } from "../../../../../redux/store/admin/permission/screen-lookup.action";
import { lookupActionAction } from "../../../../../redux/store/admin/permission/action-lookup.action";
import { updateSecurityMatrixAction } from "../../../../../redux/store/admin/permission/update-security-matrix.action";
import { getSecurityMatrixDetailAction } from "../../../../../redux/store/admin/permission/get-security-matrix-detail.action";
import Table from "react-bootstrap/Table";

const initialState = {
  screens: [],
};

class Permission extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getSecurityMatrixDetail(this.props.roleid).then((x) => {
      var lstScreen = [];
      if (this.props.getSecurityMatrixDetailState.content) {
        this.props.getSecurityMatrixDetailState.content.map((item) => {
          var actions = [];
          item.actions.map((itemAction) => {
            actions.push({
              id: itemAction.actionId,
              name: itemAction.actionName,
            });
          });
          lstScreen.push({
            screenObj: { id: item.screenId, name: item.screenName },
            actionObj: actions,
          });
        });

        this.setState({
          screens: lstScreen,
        });
      }
    });
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleClose = () => {
    this.props.onHide();
  };

  handleScreenChange = (value, index) => {
    var lstScreen = [];
    this.state.screens.map((item, i) => {
      if (i === index) {
        item.screenObj = value ? value : { id: 0, name: "" };
      }
      lstScreen.push(item);
    });
    this.setState({ screens: lstScreen });
  };

  handleActionChange = (value, index) => {
    var lstScreen = [];
    this.state.screens.map((item, i) => {
      if (i === index) {
        item.actionObj = value ? value : [];
      }
      lstScreen.push(item);
    });
    this.setState({ screens: lstScreen });
  };

  handleAddScreen = () => {
    var lstScreen = [];
    this.state.screens.map((item, i) => {
      lstScreen.push(item);
    });
    lstScreen.push({
      screenObj: { id: 0, name: "" },
      actionObj: [],
    });
    this.setState({ screens: lstScreen });
  };

  handleDeleteScreen = (index) => {
    var array = [...this.state.screens]; // make a separate copy of the array
    array.splice(index, 1);
    this.setState({ screens: array });
  };

  validate() {
    if (this.state.screens.length > 0) {
      var err = [];
      this.state.screens.map((item, i) => {
        if (item.screenObj.id === 0) {
          err.push("screen" + i);
        }
        if (item.actionObj.length <= 0) {
          err.push("action" + i);
        }
      });
      if (err.length > 0) {
        var screen = document.getElementsByName(err[0])[0];
        screen.focus();
        if (err[0].includes("screen")) {
          ShowNotification(
            "Bạn chưa chọn chức năng",
            NotificationMessageType.Error
          );
          return false;
        } else {
          ShowNotification(
            "Bạn chưa chọn quyền",
            NotificationMessageType.Error
          );
          return false;
        }
      }
    }
    return true;
  }

  handleSubmit = () => {
    if (this.validate()) {
      var lstScreen = [];

      this.state.screens.map((item) => {
        var actions = [];
        item.actionObj.map((itemAction) => {
          actions.push({
            actionId: itemAction.id,
          });
        });
        lstScreen.push({
          screenId: item.screenObj.id,
          actions: actions,
        });
      });

      var param = {
        roleId: this.props.roleid,
        screens: lstScreen,
      };
      this.props.updateSecurityMatrix(param).then((x) => {
        if (this.props.updateSecurityMatrixState.content) {
          this.props.onHide();
        }
      });
    }
  };

  render() {
    return (
      <Modal
        {...this.props}
        centered
        dialogClassName="modal-login"
        className="dialog-login"
        scrollable={true}
        size="lg"
        className="permission"
      >
        <Modal.Header closeButton>
          <Modal.Title>Phân quyền chức năng</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* onSubmit={this.handleSubmit} */}
            <Form>
              <Table responsive>
                <tbody>
                  {this.state.screens.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <Autocomplete
                            className="w-autoComplete"
                            value={item.screenObj}
                            onChange={(e, value) => {
                              this.handleScreenChange(value, index);
                            }}
                            options={
                              this.props.lookupScreenState
                                ? this.props.lookupScreenState.content
                                : [{ id: 0, name: "" }]
                            }
                            getOptionLabel={(option) => option.name}
                            style={{}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Màn hình"
                                placeholder="Chọn chức năng"
                                variant="outlined"
                                size="small"
                                required
                                name={"screen" + index}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Autocomplete
                            className="w-autoComplete"
                            multiple
                            limitTags={1}
                            value={item.actionObj}
                            options={
                              this.props.lookupActionState
                                ? this.props.lookupActionState.content
                                : [{ id: 0, name: "" }]
                            }
                            onChange={(e, value) => {
                              this.handleActionChange(value, index);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={"action" + index}
                                variant="outlined"
                                label="Hành động"
                                placeholder="Hành động"
                                size="small"
                                required
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => this.handleDeleteScreen(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan="3">
                      <a className="addNew" onClick={this.handleAddScreen}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Thêm mới chức năng
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            HỦY
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-save mg-r-10"
              viewBox="0 0 16 16"
            >
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
            </svg>
            CẬP NHẬT
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  lookupScreenState: state.lookupScreen,
  lookupActionState: state.lookupAction,
  getSecurityMatrixDetailState: state.getSecurityMatrixDetail,
  updateSecurityMatrixState: state.updateSecurityMatrix,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getLookupScreen: lookupScreenAction.getLookupScreen,
      // getLookupAction: lookupActionAction.getLookupAction,
      getSecurityMatrixDetail:
        getSecurityMatrixDetailAction.getSecurityMatrixDetail,
      updateSecurityMatrix: updateSecurityMatrixAction.updateSecurityMatrix,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Permission);
