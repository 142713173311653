import {
  GET_PROJECT_ERR,
  GET_PROJECT_OK,
  DELETE_PROJECT_ERR,
  DELETE_PROJECT_OK,
  CREATE_PROJECT_ERR,
  UPD_PROJECT_ERR,
  UPD_PROJECT_OK,
} from "./project.action";

const InitState = {
  error: null,
  content: null,
};

export default function ProjectAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_PROJECT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_PROJECT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_PROJECT_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_PROJECT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_PROJECT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case UPD_PROJECT_OK: {
      const itemParameter = action.payload;
      return {
        ...state,
        content: {
          items: state.content.items.map((item) =>
            item.id === itemParameter.id
              ? // transform the one with a matching id
                {
                  ...item,
                  name: itemParameter.name,
                  order: itemParameter.order,
                  isActive: itemParameter.isActive,
                }
              : // otherwise return original todo
                item
          ),
        },
      };
    }
    case UPD_PROJECT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
