import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_DEPARTMENT_OK = "GET_DEPARTMENT_OK";
export const GET_DEPARTMENT_ERR = "GET_DEPARTMENT_ERR";
export const DELETE_DEPARTMENT_OK = "DELETE_DEPARTMENT_OK";
export const DELETE_DEPARTMENT_ERR = "DELETE_DEPARTMENT_ERR";
export const CREATE_DEPARTMENT_ERR = "CREATE_DEPARTMENT_ERR";
export const UPD_DEPARTMENT_ERR = "UPD_DEPARTMENT_ERR";
export const UPD_DEPARTMENT_OK = "UPD_DEPARTMENT_OK";
export const GET_DEPARTMENT_LOOKUP_OK = "GET_DEPARTMENT_LOOKUP_OK";
export const GET_DEPARTMENT_LOOKUP_ERR = "GET_DEPARTMENT_LOOKUP_ERR";

export const departmentAction = {
  getDepartment,
  deleteDepartment,
  createDepartment,
  updDepartment,
  getDepartmentLookup,
};
function updDepartmentOk(payload) {
  return { type: UPD_DEPARTMENT_OK, payload };
}
function updDepartmentErr(payload) {
  return { type: UPD_DEPARTMENT_ERR, payload };
}
function createDepartmentErr(payload) {
  return { type: CREATE_DEPARTMENT_ERR, payload };
}
function deleteDepartmentOk(payload) {
  return { type: DELETE_DEPARTMENT_OK, payload };
}
function deleteDepartmentErr(payload) {
  return { type: DELETE_DEPARTMENT_ERR, payload };
}
function getDepartmentOk(payload) {
  return { type: GET_DEPARTMENT_OK, payload };
}
function getDepartmentErr(payload) {
  return { type: GET_DEPARTMENT_ERR, payload };
}
function getDepartmentLookupOk(payload) {
  return { type: GET_DEPARTMENT_LOOKUP_OK, payload };
}
function getDepartmentLookupErr(payload) {
  return { type: GET_DEPARTMENT_LOOKUP_ERR, payload };
}

function updDepartment(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateDepartmentAdm, data)
      .then((response) => {
        dispatch(updDepartmentOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updDepartmentErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createDepartment(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateDepartmentAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getDepartment());
      })
      .catch((error) => {
        dispatch(createDepartmentErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteDepartment(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteDepartmentAdm + data.Id)
      .then((response) => {
        dispatch(deleteDepartmentOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteDepartmentErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getDepartment(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListAllDepartmentAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getDepartmentOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getDepartmentErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getDepartmentLookup() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupDepartmentAdm)
      .then((response) => {
        dispatch(getDepartmentLookupOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getDepartmentLookupErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
