import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./record-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function RecordModal(props) {
  const [labelFile, setLabelFile] = useState("Chọn File...");
  const [file, setFile] = useState(null);
  const [content, setContent] = useState("");
  const [recordsNo, setRecordsNo] = useState("");

  useEffect(() => {}, [props]);

  const onSave = () => {
    props.data.handleCloseModal("save-record", {
      file: file,
      description: content,
      recordsNo: recordsNo,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-record", {});
    resetState();
  };

  const resetState = () => {
    setLabelFile("Chọn File...");
    setFile(null);
    setContent("");
    setRecordsNo("");
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Upload giấy chứng nhận</label>
            <div class="custom-file" id="customFile" lang="es">
              <input
                type="file"
                class="custom-file-input"
                id="exampleInputFile"
                aria-describedby="fileHelp"
                onChange={(e) => {
                  if (e.target.files[0] && e.target.files[0].name) {
                    setLabelFile(e.target.files[0].name);
                    setFile(e.target.files[0]);
                  } else {
                    setLabelFile("Chọn File...");
                  }
                }}
              />
              <label class="custom-file-label" for="exampleInputFile">
                {labelFile}
              </label>
            </div>
          </div>
          {props.data.typeRecord === "UPDATERESULT" &&
            !props.data.isNoCertification && (
              <div class="form-group">
                <label for="exampleInputPassword1">Số hồ sơ</label>
                <input
                  name="title"
                  type="text"
                  class="form-control"
                  id="validationDefault01"
                  value={recordsNo}
                  onChange={(event) => {
                    setRecordsNo(event.target.value);
                  }}
                />
              </div>
            )}
          {props.data.typeRecord === "UPDATERESULT" && (
            <div class="form-group">
              <label for="exampleInputPassword1">Mô tả</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                value={content}
                onChange={(event) => {
                  setContent(event.target.value);
                }}
                rows="5"
              ></textarea>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={onSave}>
          Lưu Thay Đổi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RecordModal);
