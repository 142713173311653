import { LOGIN_OK, LOGIN_ERR, LOGIN_OUT } from "./acc-adm.action";

const InitState = {
  error: null,
  content: null,
};

export default function LoginAdminReducer(state = InitState, action) {
  switch (action.type) {
    case LOGIN_OK: {
      return {
        ...state,
        error: null,
        content: action.payload,
      };
    }
    case LOGIN_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case LOGIN_OUT: {
      //return InitState;
      return {
        ...state,
        error: null,
        content: null,
      };
    }
    default:
      return state;
  }
}
