import {
  GET_SECURITY_MATRIX_DETAIL_OK,
  GET_SECURITY_MATRIX_DETAIL_ERR,
} from "./get-security-matrix-detail.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetSecurityMatrixDetailReducer(
  state = InitState,
  action
) {
  switch (action.type) {
    case GET_SECURITY_MATRIX_DETAIL_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_SECURITY_MATRIX_DETAIL_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
