import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./leader-comment-modal.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import apiEnvironment from "../../../../api/api-config";
var FileSaver = require("file-saver");

function LeaderCommentModal(props) {
  const [labelFiles, setLabelFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [content, setContent] = useState("");
  const [isNoCertification, setIsNoCertification] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);

  useEffect(() => {
    if (props.data.currentStep) {
      setContent(props.data.currentStep.description);
      setIsNoCertification(props.data.currentStep.isNoCertification);
      //set file
      if (props.data.currentStep.listFile) {
        let dataFile = [];
        props.data.currentStep.listFile.map((itemF, indexF) => {
          if (indexF === props.data.currentStep.listFile.length - 1) {
            dataFile.push(
              <a
                href="#"
                onClick={() => {
                  FileSaver.saveAs(
                    apiEnvironment.api + itemF.filePreview,
                    itemF.fileName
                  );
                }}
              >
                <span class="clTitleSup">{itemF.fileName}</span>
              </a>
            );
          } else {
            dataFile.push(
              <a
                href="#"
                onClick={() => {
                  FileSaver.saveAs(
                    apiEnvironment.api + itemF.filePreview,
                    itemF.fileName
                  );
                }}
              >
                <span class="clTitleSup">{itemF.fileName}, </span>
              </a>
            );
          }
        });
        setFileUploaded(dataFile);
      } else {
        setFileUploaded([]);
      }
    }
  }, [props]);

  const onSave = () => {
    props.data.handleCloseModal("save-leader", {
      fileList: files,
      description: content,
      isNoCertification: isNoCertification,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-leader", {});
    resetState();
  };

  const resetState = () => {
    setLabelFiles([]);
    setFiles([]);
    setContent("");
    setIsNoCertification(false);
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cập Nhập Ý Kiến</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Chọn File</label>
            <div class="custom-file" id="customFile" lang="es">
              <input
                multiple
                type="file"
                class="custom-file-input"
                id="exampleInputFile"
                aria-describedby="fileHelp"
                onChange={(e) => {
                  setFiles([]);
                  let items = [];
                  let listF = [];
                  for (let j = 0; j < e.target.files.length; j++) {
                    items.push(e.target.files[j].name);
                    listF.push(e.target.files[j]);
                  }
                  setFiles(listF);
                  setLabelFiles(items);
                }}
              />
              <label class="custom-file-label" for="exampleInputFile">
                {labelFiles.length === 0
                  ? "Chọn file..."
                  : "Bạn đã chọn " + labelFiles.length + " file"}
              </label>
            </div>
            <small id="emailHelp" class="form-text text-muted">
              Bạn có thể chọn nhiều file.
            </small>
            <div>
              {labelFiles.map(function (item, index) {
                if (index === labelFiles.length - 1) {
                  return item;
                } else {
                  return item + ", ";
                }
              })}
            </div>
          </div>
          {fileUploaded.length > 0 && (
            <div class="form-group">
              <label class="w-100" for="exampleInputPassword1">
                File đã tải lên
              </label>
              <div class="pl-2">{fileUploaded}</div>
            </div>
          )}
          <div class="form-group">
            <label for="exampleInputPassword1">Mô tả</label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              value={content}
              onChange={(event) => {
                setContent(event.target.value);
              }}
              rows="5"
            ></textarea>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              checked={isNoCertification}
              onChange={(event) => {
                setIsNoCertification(!isNoCertification);
              }}
              id="defaultCheck1"
              name="isHot"
            />
            <label class="form-check-label" for="exampleCheck1">
              Không có chứng nhận
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={onSave}>
          Lưu Thay Đổi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LeaderCommentModal);
