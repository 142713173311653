import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_WEBLINK_OK = "GET_WEBLINK_OK";
export const GET_WEBLINK_ERR = "GET_WEBLINK_ERR";
export const DELETE_WEBLINK_OK = "DELETE_WEBLINK_OK";
export const DELETE_WEBLINK_ERR = "DELETE_WEBLINK_ERR";
///export const CREATE_WEBLINK_OK = "CREATE_WEBLINK_OK";
export const CREATE_WEBLINK_ERR = "CREATE_WEBLINK_ERR";
export const UPD_WEBLINK_ERR = "UPD_WEBLINK_ERR";
export const UPD_WEBLINK_OK = "UPD_WEBLINK_OK";

export const weblinkAction = {
  getWeblink,
  deleteWeblink,
  createWeblink,
  updWeblink,
};
function updWeblinkOk(payload) {
  return { type: UPD_WEBLINK_OK, payload };
}
function updWeblinkErr(payload) {
  return { type: UPD_WEBLINK_ERR, payload };
}
//function createWeblinkOk(payload) {
//  return { type: CREATE_WEBLINK_OK, payload };
//}
function createWeblinkErr(payload) {
  return { type: CREATE_WEBLINK_ERR, payload };
}
function deleteWeblinkOk(payload) {
  return { type: DELETE_WEBLINK_OK, payload };
}
function deleteWeblinkErr(payload) {
  return { type: DELETE_WEBLINK_ERR, payload };
}
function getWeblinkOk(payload) {
  return { type: GET_WEBLINK_OK, payload };
}
function getWeblinkErr(payload) {
  return { type: GET_WEBLINK_ERR, payload };
}

function updWeblink(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateLinkServiceAdm, data)
      .then((response) => {
        dispatch(updWeblinkOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updWeblinkErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createWeblink(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateLinkServiceAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getWeblink());
      })
      .catch((error) => {
        dispatch(createWeblinkErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteWeblink(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteLinkServiceAdm + "?Id=" + data.Id)
      .then((response) => {
        dispatch(deleteWeblinkOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteWeblinkErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getWeblink(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(
        ApiUrl.GetListLinkServiceAdm + "?pageIndex=1&pageSize=100000&groupId=0"
      )
      .then((response) => {
        dispatch(getWeblinkOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getWeblinkErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
