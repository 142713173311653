import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_LIST_ALL_BY_STATUS_OK = "GET_LIST_ALL_BY_STATUS_OK";
export const GET_LIST_ALL_BY_STATUS_ERR = "GET_LIST_ALL_BY_STATUS_ERR";
export const GET_BY_ID_OK = "GET_BY_ID_OK";
export const GET_BY_ID_ER = "GET_BY_ID_ER";

export const evaluateRecordsAction = {
  getListAllByStatus,
  getById,
};
function getListAllByStatusOk(payload) {
  return { type: GET_LIST_ALL_BY_STATUS_OK, payload };
}
function getListAllByStatusErr(payload) {
  return { type: GET_LIST_ALL_BY_STATUS_ERR, payload };
}
function getByIdOk(payload) {
  return { type: GET_BY_ID_OK, payload };
}
function getByIdErr(payload) {
  return { type: GET_BY_ID_ER, payload };
}

function getById(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetEvaluateRecordsByID + data.id)
      .then((response) => {
        dispatch(getByIdOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getByIdErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getListAllByStatus(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.GetListAllByStatus, data)
      .then((response) => {
        dispatch(getListAllByStatusOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getListAllByStatusErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
