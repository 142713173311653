import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor from "ckeditor5-build-classic-b";
//import ClassicEditor from "../common/build/ckeditor";
//import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import { ApiUrl } from "../../../api/api-url";
import apiEnvironment from "../../../api/api-config";

class TextEditor extends React.Component {
  render() {
    const { value, onChange, onInstance } = this.props;
    const custom_config = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      toolbar: {
        items: [
          "heading",
          "|",
          "alignment",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "outdent",
          "indent",
          "|",
          "uploadImage",
          "blockQuote",
          "insertTable",
          "mediaEmbed",
          "undo",
          "redo",
        ],
      },
      image: {
        // Configure the available styles.
        styles: ["alignLeft", "alignCenter", "alignRight"],

        // Configure the available image resize options.
        resizeOptions: [
          {
            name: "resizeImage:original",
            label: "Original",
            value: null,
          },
          {
            name: "resizeImage:50",
            label: "50%",
            value: "50",
          },
          {
            name: "resizeImage:75",
            label: "75%",
            value: "75",
          },
        ],

        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "resizeImage",
          "|",
          "imageTextAlternative",
        ],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
    };

    return (
      <CKEditor
        required
        editor={ClassicEditor}
        config={custom_config}
        data={value}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          onInstance(editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
      />
    );
  }
}

export default TextEditor;

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = apiEnvironment.api + ApiUrl.UploadNewsImageAdm;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open("POST", this.url, true);
    xhr.responseType = "json";
    const userInfo = JSON.parse(sessionStorage.getItem("adminpcc"));
    if (userInfo && userInfo.token) {
      xhr.setRequestHeader("Authorization", "Bearer " + userInfo.token);
    }
    /*
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Authorization", "Bearer " + getToken());
    xhr.setRequestHeader("Authorization", "ABC");
    */
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: response.content.url,
        //default: "http://example.com/images/image–default-size.png",
        //160: "http://example.com/images/image–size-160.image.png",
        //500: "http://example.com/images/image–size-500.image.png",
        //1000: "http://example.com/images/image–size-1000.image.png",
        //1052: "http://example.com/images/image–default-size.png",
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result) => {
      data.append("upload", result);
      this.xhr.send(data);
    });
  }
}
