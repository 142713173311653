import React from "react";
import { connect } from "react-redux";

function LoadingWithApiQueue(props) {
    return (
        <div>
            {
                (props.loadingQueue.length > 0) && <div id="overlay">
                    <div class="spinner-adm">
                        <div class="spinner-border text-primary spinner-wh" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
              </div>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    loadingQueue: state.loadingState.loadingQueue,
});

export default connect(mapStateToProps, null)(LoadingWithApiQueue);
