/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Service from "../../../api/api-service";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { ApiUrl } from "../../../api/api-url";
import DateFormat from "dateformat";
import "./delegacy.scss";
import { CONSTANT } from "../../../constants/constant";
import DelegacyModal from "../modal/delegacy-modal/delegacy-modal";
import { lookupDocumentTypeAction } from "../../../redux/store/admin/lookup-document-type/lookup-document-type.action";
import { userAction } from "../../../redux/store/admin/user/user.action";
import { delegacytAction } from "../../../redux/store/admin/delegacy/delegacy.action";

const service = new Service();
let dataInput = {};

class Delegacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      items: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 15,
      rowId: null,
      delegacyShow: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.changeData = this.changeData.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    this.props.getLookupDocumentType();
    this.props.getUserByDeparment(() => {
      let userByDepartment = [];
      this.props.userByDepartment.content.map((item, index) => {
        //let depName = item.name;
        item.users.map((itemSub, indexSub) => {
          userByDepartment.push({
            id: itemSub.id,
            name:
              //depName +
              //" - " +
              itemSub.positionName +
              " - " +
              itemSub.rankingName +
              " - " +
              itemSub.fullName,
          });
        });
      });
      dataInput["userByDepartment"] = userByDepartment;
    });
    this.fetchData();
  }
  handleShowModal() {
    dataInput["type"] = false;
    this.setState({ delegacyShow: true });
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close":
        this.setState({ delegacyShow: false });
        break;
      case "save":
        if (data.type) {
          this.props.updDelegacy(
            {
              startDate: data.startDate,
              endDate: data.endDate,
              description: data.description,
              isActive: data.isActive,
              waitingTime: data.waitingTime,
              waitingType: data.waitingType,
              apprType: data.apprType,
              id: this.state.id,
            },
            () => {
              this.fetchData();
            }
          );
          this.setState({ delegacyShow: false });
        } else {
          this.props.createDelegacy(
            {
              documentTypeId: data.documentTypeId,
              docApprStepId: data.docApprStepId,
              step: data.step,
              userDelegacyId: data.userDelegacyId,
              startDate: data.startDate,
              endDate: data.endDate,
              description: data.description,
              isActive: data.isActive,
              waitingTime: data.waitingTime,
              waitingType: data.waitingType,
              apprType: data.apprType,
            },
            () => {
              this.fetchData();
            }
          );
          this.setState({ delegacyShow: false });
        }
        break;
      default:
        break;
    }
  }
  fetchData() {
    let page = this.state.page;
    let sizePerPage = this.state.sizePerPage;
    this.props.ShowLoading();
    service
      .get(
        ApiUrl.GetListAllDelegacy + `?pageIndex=${page}&pageSize=${sizePerPage}`
      )
      .then((response) => {
        this.setState({
          items: response.content.items,
          totalSize: response.content.totalItemCount,
          page,
          sizePerPage,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  changeData(type, newState) {
    let page = newState.page;
    let sizePerPage = newState.sizePerPage;
    this.props.ShowLoading();
    service
      .get(
        ApiUrl.GetListAllDelegacy + `?pageIndex=${page}&pageSize=${sizePerPage}`
      )
      .then((response) => {
        this.setState({
          items: response.content.items,
          totalSize: response.content.totalItemCount,
          page,
          sizePerPage,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  render() {
    const columns = [
      {
        dataField: "documentTypeName",
        text: "Loại chứng từ",
        headerStyle: () => {
          return { width: "25%" };
        },
      },
      {
        dataField: "docApprStepName",
        text: "Bước xét duyệt",
        headerStyle: () => {
          return { width: "15%" };
        },
      },
      {
        dataField: "userDelegacyFullName",
        text: "Ủy quyền cho",
        headerStyle: () => {
          return { width: "12%" };
        },
      },
      {
        dataField: "startDate",
        text: "Duyệt từ ngày",
        formatter: (cell) => {
          const val = DateFormat(cell, "dd/mm/yyyy");
          return <div style={{ overflowWrap: "break-word" }}>{val}</div>;
        },
        headerStyle: () => {
          //return { width: "16%" };
        },
      },
      {
        dataField: "endDate",
        text: "Duyệt đến ngày",
        formatter: (cell) => {
          const val = DateFormat(cell, "dd/mm/yyyy");
          return <div style={{ overflowWrap: "break-word" }}>{val}</div>;
        },
        headerStyle: () => {
          //return { width: "16%" };
        },
      },
      {
        dataField: "apprType",
        text: "Loại duyệt",
        formatter: (cell) => {
          let val = "";
          switch (cell) {
            case 1:
              val = "Duyệt thủ công";
              break;
            case 2:
              val = "Tự động duyệt";
              break;
            case 3:
              val = "Tự động duyệt khi hết thời gian chờ";
              break;
            default:
              break;
          }
          return val;
        },
      },
      {
        dataField: "description",
        text: "Ghi chú",
      },
      {
        dataField: "isActive",
        text: "Kích hoạt",
        formatter: (cell) => {
          return cell ? CONSTANT.label.active : CONSTANT.label.deactive;
        },
        headerStyle: () => {
          //return { width: "12%" };
        },
      },
    ];
    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            alwaysShowAllBtns: true,
            hideSizePerPage: true,
          })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
          classes="table-striped"
          rowClasses="mytable-hover-row"
        />
      </div>
    );
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        dataInput["documentTypeId"] = row.documentTypeId;
        dataInput["docApprStepId"] = row.docApprStepId;
        dataInput["userDelegacyId"] = row.userDelegacyId;
        dataInput["waitingTime"] = row.waitingTime;
        dataInput["waitingType"] = row.waitingType;
        dataInput["apprType"] = row.apprType;
        dataInput["startDate"] = row.startDate;
        dataInput["endDate"] = row.endDate;
        dataInput["description"] = row.description;
        dataInput["status"] = row.isActive;
        dataInput["type"] = true;
        this.setState({
          ...this.state,
          id: row.id,
          delegacyShow: true,
        });
      },
    };
    return (
      <div class="layout-container pd15 province">
        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
          Ủy quyền xét duyệt
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <RemotePagination
              data={this.state.items}
              page={this.state.page}
              sizePerPage={this.state.sizePerPage}
              totalSize={this.state.totalSize}
              onTableChange={(type, newState) => {
                this.changeData(type, newState);
              }}
            />
          </div>
          <div class="row no-gutters vertical-center mg-botton2">
            <div class="col d-flex justify-content-center">
              <button
                class="btn btn-primary"
                name="create"
                type="submit"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                Tạo Mới
              </button>
            </div>
          </div>
        </div>
        <DelegacyModal
          data={{
            show: this.state.delegacyShow,
            handleCloseModal: this.handleCloseModal,
            dataInput: dataInput,
          }}
        ></DelegacyModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userByDepartment: state.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
      getLookupDocumentType: lookupDocumentTypeAction.getLookupDocumentType,
      getUserByDeparment: userAction.getUserByDeparment,
      createDelegacy: delegacytAction.createDelegacy,
      updDelegacy: delegacytAction.updDelegacy,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Delegacy);
