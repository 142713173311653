import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const CREATE_DELEGACY_ERR = "CREATE_DELEGACY_ERR";
export const UPD_DELEGACY_ERR = "UPD_DELEGACY_ERR";

export const delegacytAction = {
  createDelegacy,
  updDelegacy,
};
function updDelegacyErr(payload) {
  return { type: UPD_DELEGACY_ERR, payload };
}
function createDelegacyErr(payload) {
  return { type: CREATE_DELEGACY_ERR, payload };
}

function updDelegacy(data, callback) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DocApprDelegacyUpd, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        callback();
      })
      .catch((error) => {
        dispatch(updDelegacyErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createDelegacy(data, callback) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DocApprDelegacyCreate, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        callback();
      })
      .catch((error) => {
        dispatch(createDelegacyErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
