import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_PROVINCE_OK = "GET_PROVINCE_OK";
export const GET_PROVINCE_ERR = "GET_PROVINCE_ERR";
export const DELETE_PROVINCE_OK = "DELETE_PROVINCE_OK";
export const DELETE_PROVINCE_ERR = "DELETE_PROVINCE_ERR";
export const CREATE_PROVINCE_ERR = "CREATE_PROVINCE_ERR";
export const UPD_PROVINCE_ERR = "UPD_PROVINCE_ERR";
export const UPD_PROVINCE_OK = "UPD_PROVINCE_OK";

export const provinceAction = {
  getProvince,
  deleteProvince,
  createProvince,
  updProvince,
};
function updProvinceOk(payload) {
  return { type: UPD_PROVINCE_OK, payload };
}
function updProvinceErr(payload) {
  return { type: UPD_PROVINCE_ERR, payload };
}
function createProvinceErr(payload) {
  return { type: CREATE_PROVINCE_ERR, payload };
}
function deleteProvinceOk(payload) {
  return { type: DELETE_PROVINCE_OK, payload };
}
function deleteProvinceErr(payload) {
  return { type: DELETE_PROVINCE_ERR, payload };
}
function getProvinceOk(payload) {
  return { type: GET_PROVINCE_OK, payload };
}
function getProvinceErr(payload) {
  return { type: GET_PROVINCE_ERR, payload };
}

function updProvince(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateProvinceAdm, data)
      .then((response) => {
        dispatch(updProvinceOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updProvinceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createProvince(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateProvinceAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getProvince());
      })
      .catch((error) => {
        dispatch(createProvinceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteProvince(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteProvinceAdm + "?ProvinceId=" + data.Id)
      .then((response) => {
        dispatch(deleteProvinceOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteProvinceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getProvince(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListProvinceAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getProvinceOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getProvinceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
