import UrlCollect from "./common/url-collect";

//--- Layout
import LayoutAdminView from "./components/layout/layout-admin.view";
import LayoutAdminLoginView from "./components/layout/layout-admin-login.view";

//--- Admin
import LoginAdm from "./modules/admin/login/login";
import DashboardAdmin from "./modules/admin/dashboard/dashboard-admin";
import DocumentNew from "./modules/admin/document-new/document-new";
import DocumentNewDetail from "./modules/admin/document-new-detail/document-new-detail";
import Weblink from "./modules/admin/weblink/weblink";
import Province from "./modules/admin/province/province";
import District from "./modules/admin/district/district";
import Commune from "./modules/admin/commune/commune";
import Project from "./modules/admin/project/project";
import Department from "./modules/admin/department/department";
import Position from "./modules/admin/position/position";
import News from "./modules/admin/news/news";
import Account from "./modules/admin/account/account";
import ChangePassword from "./modules/admin/change-password/change-password";
import Qualify from "./modules/admin/qualify/qualify";
import Delegacy from "./modules/admin/delegacy/delegacy";
import ListUser from "./modules/admin/manager-user/list-user.view";
import Role from "./modules/admin/role/role.view";

const rolesAdmin = ["admin", "manage"];

var routers = [
  //Admin ===============================================================
  {
    path: UrlCollect.AdmLogin,
    component: LoginAdm,
    layout: LayoutAdminLoginView,
    isAdmin: true,
  },
  {
    path: UrlCollect.ChangePass,
    component: ChangePassword,
    layout: LayoutAdminView,
    isAdmin: true,
  },
  {
    path: UrlCollect.DashboardAdmin,
    component: DashboardAdmin,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentWait,
    component: DocumentNew,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentNew,
    component: DocumentNew,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentProcess,
    component: DocumentNew,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentReg,
    component: DocumentNew,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentEval,
    component: DocumentNew,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentWaitDetailPattern,
    component: DocumentNewDetail,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentNewDetailPattern,
    component: DocumentNewDetail,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentProcessDetailPattern,
    component: DocumentNewDetail,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentRegDetailPattern,
    component: DocumentNewDetail,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.DocumentEvalDetailPattern,
    component: DocumentNewDetail,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Weblink,
    component: Weblink,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Province,
    component: Province,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.District,
    component: District,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Commune,
    component: Commune,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Project,
    component: Project,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Department,
    component: Department,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Position,
    component: Position,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.News,
    component: News,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Account,
    component: Account,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Qualify,
    component: Qualify,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Delegacy,
    component: Delegacy,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.ManagerUser,
    component: ListUser,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  {
    path: UrlCollect.Role,
    component: Role,
    layout: LayoutAdminView,
    roles: rolesAdmin,
    isAdmin: true,
  },
  { redirect: true, path: "/", pathTo: "/" },
];
export default routers;
