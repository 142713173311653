import React from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import "./filter-role.scss";
import { userManagement } from "../../../../../redux/store/admin/manager-user/manager-user.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@material-ui/core/TextField";

const initialState = {
  code: "",
  name: "",
};

class FilterRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  filter = (e) => {
    e.preventDefault();
    this.props.onFilter(this.state.code, this.state.name);
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        // {...this.props}
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        dialogClassName="modal-login"
        className="dialog-login"
        scrollable={true}
        size="sm"
        className="filter-user"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Tìm kiếm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Code</Form.Label>
                <Form.Control
                  name="code"
                  onChange={this.handleChange}
                  required
                  type="text"
                  value={this.state.code}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Tên role</Form.Label>
                <Form.Control
                  name="name"
                  onChange={this.handleChange}
                  required
                  type="text"
                  value={this.state.name}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.filter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
            TÌM KIẾM
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ name: "", code: "" })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            BỎ LỌC
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FilterRole);
