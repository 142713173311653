import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_POSITION_OK = "GET_POSITION_OK";
export const GET_POSITION_ERR = "GET_POSITION_ERR";
export const DELETE_POSITION_OK = "DELETE_POSITION_OK";
export const DELETE_POSITION_ERR = "DELETE_POSITION_ERR";
export const CREATE_POSITION_ERR = "CREATE_POSITION_ERR";
export const UPD_POSITION_ERR = "UPD_POSITION_ERR";
export const UPD_POSITION_OK = "UPD_POSITION_OK";
export const GET_POSITION_LOOKUP_OK = "GET_POSITION_LOOKUP_OK";
export const GET_POSITION_LOOKUP_ERR = "GET_POSITION_LOOKUP_ERR";

export const positionAction = {
  getPosition,
  deletePosition,
  createPosition,
  updPosition,
  getPositionLookup,
};
function updPositionOk(payload) {
  return { type: UPD_POSITION_OK, payload };
}
function updPositionErr(payload) {
  return { type: UPD_POSITION_ERR, payload };
}
function createPositionErr(payload) {
  return { type: CREATE_POSITION_ERR, payload };
}
function deletePositionOk(payload) {
  return { type: DELETE_POSITION_OK, payload };
}
function deletePositionErr(payload) {
  return { type: DELETE_POSITION_ERR, payload };
}
function getPositionOk(payload) {
  return { type: GET_POSITION_OK, payload };
}
function getPositionErr(payload) {
  return { type: GET_POSITION_ERR, payload };
}
function getPositionLookupOk(payload) {
  return { type: GET_POSITION_LOOKUP_OK, payload };
}
function getPositionLookupErr(payload) {
  return { type: GET_POSITION_LOOKUP_ERR, payload };
}

function updPosition(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdatePositionAdm, data)
      .then((response) => {
        dispatch(updPositionOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updPositionErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createPosition(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreatePositionAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getPosition());
      })
      .catch((error) => {
        dispatch(createPositionErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deletePosition(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeletePositionAdm + data.Id)
      .then((response) => {
        dispatch(deletePositionOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deletePositionErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getPosition(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListAllPositionAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getPositionOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getPositionErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getPositionLookup() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupPositionAdm)
      .then((response) => {
        dispatch(getPositionLookupOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getPositionLookupErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
