import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_DISTRICT_OK = "GET_DISTRICT_OK";
export const GET_DISTRICT_ERR = "GET_DISTRICT_ERR";
export const DELETE_DISTRICT_OK = "DELETE_DISTRICT_OK";
export const DELETE_DISTRICT_ERR = "DELETE_DISTRICT_ERR";
export const CREATE_DISTRICT_ERR = "CREATE_DISTRICT_ERR";
export const UPD_DISTRICT_ERR = "UPD_DISTRICT_ERR";
export const UPD_DISTRICT_OK = "UPD_DISTRICT_OK";

export const districtAction = {
  getDistrict,
  deleteDistrict,
  createDistrict,
  updDistrict,
};
function updDistrictOk(payload) {
  return { type: UPD_DISTRICT_OK, payload };
}
function updDistrictErr(payload) {
  return { type: UPD_DISTRICT_ERR, payload };
}
function createDistrictErr(payload) {
  return { type: CREATE_DISTRICT_ERR, payload };
}
function deleteDistrictOk(payload) {
  return { type: DELETE_DISTRICT_OK, payload };
}
function deleteDistrictErr(payload) {
  return { type: DELETE_DISTRICT_ERR, payload };
}
function getDistrictOk(payload) {
  return { type: GET_DISTRICT_OK, payload };
}
function getDistrictErr(payload) {
  return { type: GET_DISTRICT_ERR, payload };
}

function updDistrict(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateDistrictAdm, data)
      .then((response) => {
        dispatch(updDistrictOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updDistrictErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createDistrict(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateDistrictAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getDistrict());
      })
      .catch((error) => {
        dispatch(createDistrictErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteDistrict(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteDistrictAdm + "?Id=" + data.Id)
      .then((response) => {
        dispatch(deleteDistrictOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteDistrictErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getDistrict(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListDistrictAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getDistrictOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getDistrictErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
