import {
  GET_NEWS_CATEGORY_ERR,
  GET_NEWS_CATEGORY_OK,
  DELETE_NEWS_CATEGORY_ERR,
  DELETE_NEWS_CATEGORY_OK,
  CREATE_NEWS_CATEGORY_ERR,
  UPD_NEWS_CATEGORY_ERR,
  //UPD_NEWS_CATEGORY_OK,
} from "./news.action";

const InitState = {
  error: null,
  content: null,
};

export default function NewsCategoryAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_NEWS_CATEGORY_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_NEWS_CATEGORY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_NEWS_CATEGORY_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_NEWS_CATEGORY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_NEWS_CATEGORY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    /*
    case UPD_NEWS_CATEGORY_OK: {
      const itemParameter = action.payload;
      return {
        ...state,
        content: {
          items: state.content.items.map((item) =>
            item.id === itemParameter.id
              ? // transform the one with a matching id
                {
                  ...item,
                  categoryId: itemParameter.categoryId,
                  title: itemParameter.title,
                  documentUploadId: itemParameter.documentUploadId,
                  description: itemParameter.description,
                  content: itemParameter.content,
                  status: itemParameter.status,
                  isHot: itemParameter.isHot,
                  isFeature: itemParameter.isFeature,
                  isHomePage: itemParameter.isHomePage,

                  isDelete: itemParameter.IsDeleteImage,
                  tags: itemParameter.tags,
                  xmin: itemParameter.xmin, // incorect xmin must use value response from server
                  //file: itemParameter.file,
                }
              : // otherwise return original todo
                item
          ),
        },
      };
    }
    */
    case UPD_NEWS_CATEGORY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
