import {
  GET_LOOKUP_CATEGORY_OK,
  GET_LOOKUP_CATEGORY_ERR,
} from "./lookup-category.action";

const InitState = {
  error: null,
  content: null,
};

export default function LookupCategoryReducer(state = InitState, action) {
  switch (action.type) {
    case GET_LOOKUP_CATEGORY_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LOOKUP_CATEGORY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
