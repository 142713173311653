/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { weblinkAction } from "../../../redux/store/admin/weblink/weblink.action";
import { CONSTANT } from "../../../constants/constant";
import DeleteDialog from "../modal/dialog-delete/dialog-delete.view";
import WeblinkModal from "../modal/config/weblink-modal/weblink-modal";

import "./weblink.scss";

const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});
let dataInput = {};

class Weblink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      groupId: 0,
      isOpenDelModal: false,
      rowId: null,
      weblinkShow: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    this.props.getWeblink();
  }
  handleShowModal() {
    dataInput["type"] = false;
    this.setState({ weblinkShow: true });
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close":
        this.setState({ weblinkShow: false });
        break;
      case "save":
        if (data.type) {
          this.props.updWeblink({
            id: this.state.id,
            name: data.name,
            groupId: 0,
            link: data.link,
            order: data.order,
            active: data.status,
          });
          this.setState({ weblinkShow: false });
        } else {
          this.props.createWeblink({
            name: data.name,
            groupId: 0,
            link: data.link,
            order: data.order,
            active: data.status,
          });
          this.setState({ weblinkShow: false });
        }
        break;
      default:
        break;
    }
  }
  handleDelete() {
    this.props.deleteWeblink({ Id: this.state.rowId });
  }
  render() {
    const columns = [
      {
        dataField: "name",
        text: "Tên",
      },
      {
        dataField: "link",
        text: "Liên Kết",
        formatter: (cell) => {
          return <div style={{ overflowWrap: "break-word" }}>{cell}</div>;
        },
      },
      {
        dataField: "order",
        text: "Thứ Tự",
        headerStyle: () => {
          return { width: "8%" };
        },
      },
      {
        dataField: "active",
        text: "Trạng Thái",
        formatter: (cell) => {
          return cell ? CONSTANT.label.active : CONSTANT.label.deactive;
        },
        headerStyle: () => {
          return { width: "12%" };
        },
      },
      {
        dataField: "id",
        text: "Xóa",
        headerStyle: (colum, colIndex) => {
          return { width: "45px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  ...this.state,
                  isOpenDelModal: true,
                  rowId: row.id,
                });
              }}
            >
              <svg
                width="18"
                height="18"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash-alt"
                class="svg-inline--fa fa-trash-alt fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="red"
                  d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                ></path>
              </svg>
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        dataInput["name"] = row.name;
        dataInput["link"] = row.link;
        dataInput["order"] = row.order;
        dataInput["status"] = row.active;
        dataInput["type"] = true;
        this.setState({
          ...this.state,
          id: row.id,
          groupId: row.groupId,
          weblinkShow: true,
        });
      },
    };
    const products = [];
    if (
      this.props.weblink.content &&
      this.props.weblink.content.items.length > 0
    ) {
      this.props.weblink.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <div class="layout-container pd15 weblinkl">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
          Liên Kết Web
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              classes="table-striped"
              rowClasses="mytable-hover-row"
            />
          </div>
          <div class="row no-gutters vertical-center mg-botton2">
            <div class="col d-flex justify-content-center">
              <button
                class="btn btn-primary"
                name="create"
                type="submit"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                Tạo Mới
              </button>
            </div>
          </div>
        </div>
        <DeleteDialog
          isOpen={this.state.isOpenDelModal}
          onClose={() => {
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
          onSuccess={() => {
            this.handleDelete();
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
        ></DeleteDialog>
        {/**NewsModa */}
        <WeblinkModal
          data={{
            show: this.state.weblinkShow,
            handleCloseModal: this.handleCloseModal,
            dataInput: dataInput,
          }}
        ></WeblinkModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  weblink: state.weblink,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getWeblink: weblinkAction.getWeblink,
      deleteWeblink: weblinkAction.deleteWeblink,
      createWeblink: weblinkAction.createWeblink,
      updWeblink: weblinkAction.updWeblink,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Weblink);
