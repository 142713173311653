/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Service from "../../../api/api-service";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { newCategoryAction } from "../../../redux/store/admin/news/news.action";
import { lookupCategoryAction } from "../../../redux/store/admin/lookup-category/lookup-category.action";
import apiEnvironment from "../../../api/api-config";
import NewsModal from "../modal/news-modal/news-modal";
import { CONSTANT } from "../../../constants/constant";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import "./news.scss";
import { ApiUrl } from "../../../api/api-url";
import DeleteDialog from "../modal/dialog-delete/dialog-delete.view";

const service = new Service();
const pagination = paginationFactory({
  sizePerPage: 15,
  paginationSize: 10,
  alwaysShowAllBtns: true,
  hideSizePerPage: true,
});

let newsCategoryLookup = null;
const urlImg = apiEnvironment.api;
let dataInput = {};

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      xmin: null,
      isDeleteImage: 0,
      newsShow: false,
      isOpenDelModal: false,
      rowId: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.getDetailNews = this.getDetailNews.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentDidMount() {
    this.props.getLookupCategory();

    this.props.ShowLoading();
    service
      .get(ApiUrl.GetLookupCategoryAdm)
      .then((response) => {
        this.props.HiddenLoading();
        newsCategoryLookup = response.content;
        this.props.getNewsCategory();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close":
        this.setState({ newsShow: false });
        break;
      case "save":
        if (data.type) {
          const formData = new FormData();
          formData.append("CategoryId", data.categoryId);
          formData.append("Id", this.state.id);
          formData.append("Title", data.title);
          formData.append("Description", data.description);
          formData.append("Content", data.content);
          formData.append("Status", data.status);
          formData.append("IsHot", data.isHot);
          formData.append("IsFeature", data.isFeature);
          formData.append("IsHomePage", data.isHomePage);
          formData.append("Tags", this.state.tags);
          formData.append("xmin", this.state.xmin);
          formData.append("IsDeleteImage", this.state.isDeleteImage);
          formData.append("file", data.file);
          this.props.updNewsCategory(formData);
          this.setState({ newsShow: false });
        } else {
          const formData = new FormData();
          formData.append("CategoryId", data.categoryId);
          formData.append("Title", data.title);
          formData.append("Description", data.description);
          formData.append("Content", data.content);
          formData.append("Status", data.status);
          formData.append("IsHot", data.isHot);
          formData.append("IsFeature", data.isFeature);
          formData.append("IsHomePage", data.isHomePage);
          formData.append("Tags", this.state.tags);
          formData.append("file", data.file);
          this.props.createNewsCategory(formData);
          this.setState({ newsShow: false });
        }
        break;
      default:
        break;
    }
  }
  handleShowModal() {
    dataInput["type"] = false;
    dataInput["content"] = "";
    this.setState({ newsShow: true });
  }
  getDetailNews() {
    this.props.ShowLoading();
    service
      .get(ApiUrl.GetDetailAdm + this.state.id)
      .then((response) => {
        this.props.HiddenLoading();
        if (response.content && response.content.content) {
          this.setState({
            ...this.state,
            content: response.content.content,
            xmin: response.content.xmin,
          });
          dataInput["content"] = response.content.content;
          dataInput["xmin"] = response.content.xmin;
          this.setState({ newsShow: true });
        }
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  handleDelete() {
    this.props.deleteNewsCategory({
      Id: this.state.rowId,
      xmin: this.state.xmin,
    });
  }
  render() {
    function imgFormatter(cell, row) {
      if (row.image_Url) {
        return (
          <span>
            <img src={urlImg + row.image_Url} alt="" class="img-max-news"></img>
          </span>
        );
      }
      return <span></span>;
    }
    const columns = [
      {
        dataField: "title",
        text: "Tiêu Đề",
      },
      {
        dataField: "category.name",
        text: "Danh Mục",
        headerStyle: () => {
          return { width: "25%" };
        },
      },
      {
        dataField: "image_Url",
        text: "Ảnh",
        formatter: imgFormatter,
        headerStyle: () => {
          return { width: "12%" };
        },
      },
      {
        dataField: "status",
        text: "Trạng Thái",
        formatter: (cell) => {
          return cell ? CONSTANT.label.active : CONSTANT.label.deactive;
        },
        headerStyle: () => {
          return { width: "12%" };
        },
      },
      {
        dataField: "id",
        text: "Xóa",
        headerStyle: (colum, colIndex) => {
          return { width: "45px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  ...this.state,
                  isOpenDelModal: true,
                  rowId: row.id,
                  xmin: row.xmin,
                });
              }}
            >
              <svg
                width="18"
                height="18"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash-alt"
                class="svg-inline--fa fa-trash-alt fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="red"
                  d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                ></path>
              </svg>
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const newsCategorytem = newsCategoryLookup.find(
          (itemNewsCategory) => itemNewsCategory.id === row.categoryId
        );
        dataInput["categoryId"] = row.categoryId;
        dataInput["newsCategoryAutoCompleteSelected"] = newsCategorytem;
        dataInput["title"] = row.title;
        dataInput["description"] = row.description;
        dataInput["isHot"] = row.isHot;
        dataInput["isFeature"] = row.isFeature;
        dataInput["isHomePage"] = row.isHomePage;
        dataInput["status"] = row.status;
        dataInput["image_Url"] = row.image_Url;
        dataInput["type"] = true;
        this.setState(
          {
            ...this.state,
            id: row.id,
          },
          () => {
            this.getDetailNews();
          }
        );
      },
    };
    const products = [];
    if (
      newsCategoryLookup &&
      this.props.news.content &&
      this.props.news.content.items.length > 0
    ) {
      this.props.news.content.items.map((item, index) => {
        products.push(item);
      });
    }
    return (
      <div class="layout-container pd15 news">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
          Hướng dẫn
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2 p-2">
          <div className="col-12">
            <BootstrapTable
              rowEvents={rowEvents}
              keyField="id"
              data={products}
              columns={columns}
              pagination={pagination}
              classes="table-striped"
              rowClasses="mytable-hover-row"
            />
          </div>
          <div class="row no-gutters vertical-center">
            <div class="col d-flex justify-content-center">
              <button
                class="btn btn-primary"
                name="createModal"
                type="button"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                Tạo Mới
              </button>
            </div>
          </div>
        </div>

        {/**NewsModa */}
        <NewsModal
          data={{
            show: this.state.newsShow,
            handleCloseModal: this.handleCloseModal,
            dataInput: dataInput,
          }}
        ></NewsModal>

        {/**DeleteDialog */}
        <DeleteDialog
          isOpen={this.state.isOpenDelModal}
          onClose={() => {
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
          onSuccess={() => {
            this.handleDelete();
            this.setState({ ...this.state, isOpenDelModal: false });
          }}
        ></DeleteDialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  news: state.newsAdm,
  lookupCategory: state.lookupCategory,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getNewsCategory: newCategoryAction.getNewsCategory,
      deleteNewsCategory: newCategoryAction.deleteNewsCategory,
      createNewsCategory: newCategoryAction.createNewsCategory,
      updNewsCategory: newCategoryAction.updNewsCategory,
      getLookupCategory: lookupCategoryAction.getLookupCategory,
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(News);
