import {
  GET_WEBLINK_ERR,
  GET_WEBLINK_OK,
  DELETE_WEBLINK_ERR,
  DELETE_WEBLINK_OK,
  CREATE_WEBLINK_ERR,
  //CREATE_WEBLINK_OK,
  UPD_WEBLINK_ERR,
  UPD_WEBLINK_OK,
} from "./weblink.action";

const InitState = {
  error: null,
  content: null,
};

export default function WeblinkReducer(state = InitState, action) {
  switch (action.type) {
    case GET_WEBLINK_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_WEBLINK_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_WEBLINK_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_WEBLINK_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    //case CREATE_WEBLINK_OK: {
    //  return state;
    //}
    case CREATE_WEBLINK_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case UPD_WEBLINK_OK: {
      const itemParameter = action.payload;
      return {
        ...state,
        content: {
          items: state.content.items.map((item) =>
            item.id === itemParameter.id
              ? // transform the one with a matching id
                {
                  ...item,
                  name: itemParameter.name,
                  link: itemParameter.link,
                  order: itemParameter.order,
                  active: itemParameter.active,
                }
              : // otherwise return original todo
                item
          ),
        },
      };
    }
    case UPD_WEBLINK_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
