import {
  GET_POSITION_LOOKUP_OK,
  GET_POSITION_LOOKUP_ERR,
} from "./position.action";

const InitState = {
  error: null,
  content: null,
};

export default function PositionLookupAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_POSITION_LOOKUP_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_POSITION_LOOKUP_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
