import {
  GET_LIST_ALL_BY_STATUS_OK,
  GET_LIST_ALL_BY_STATUS_ERR,
  GET_BY_ID_OK,
  GET_BY_ID_ER,
} from "./evaluate-records.action";

const InitState = {
  error: null,
  content: null,
};

export default function EvaluateRecordsAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_LIST_ALL_BY_STATUS_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LIST_ALL_BY_STATUS_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case GET_BY_ID_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_BY_ID_ER: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
