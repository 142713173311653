import {
  GET_DISTRICT_ERR,
  GET_DISTRICT_OK,
  DELETE_DISTRICT_ERR,
  DELETE_DISTRICT_OK,
  CREATE_DISTRICT_ERR,
  UPD_DISTRICT_ERR,
  UPD_DISTRICT_OK,
} from "./district.action";

const InitState = {
  error: null,
  content: null,
};

export default function DistrictAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_DISTRICT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_DISTRICT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_DISTRICT_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_DISTRICT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_DISTRICT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case UPD_DISTRICT_OK: {
      const itemParameter = action.payload;
      return {
        ...state,
        content: {
          items: state.content.items.map((item) =>
            item.id === itemParameter.id
              ? // transform the one with a matching id
                {
                  ...item,
                  name: itemParameter.name,
                  provinceId: itemParameter.provinceId,
                }
              : // otherwise return original todo
                item
          ),
        },
      };
    }
    case UPD_DISTRICT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
