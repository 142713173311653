/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { accountAdminAction } from "../../../redux/store/admin/account-adm/acc-adm.action";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";
import ForgotPasswordModal from "../modal/forgot-password/forgot-password";
import Service from "../../../api/api-service";
import { CONSTANT } from "../../../constants/constant";
import { ApiUrl } from "../../../api/api-url";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { Button, Form, Row, Col } from "react-bootstrap";
import "./login.scss";

const service = new Service();

class LoginAdm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      rememberMe: true,
      returnUrl: "string",
      forgotPasswordModalShow: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    //check login
    const currentLoggin = JSON.parse(sessionStorage.getItem("adminpcc"));
    if (currentLoggin) {
      if (currentLoggin.role === "admin") {
        history.push(UrlCollect.DashboardAdmin);
      } else {
        history.push(UrlCollect.AdmLogin);
      }
    }
  }
  handleCloseModal(type, data) {
    switch (type) {
      case "close-forgot":
        this.setState({ forgotPasswordModalShow: false });
        break;
      case "save-forgot":
        this.props.ShowLoading();
        service
          .post(ApiUrl.ForgotPassword, {
            email: data.email,
            returnUrl: "",
          })
          .then((response) => {
            this.props.HiddenLoading();
            this.setState({ forgotPasswordModalShow: false });
            ShowNotification(
              CONSTANT.message.forgotPassword,
              NotificationMessageType.Success
            );
          })
          .catch((error) => {
            this.props.HiddenLoading();
            this.setState({ forgotPasswordModalShow: false });
            if (error && error.errorMessage) {
              ShowNotification(
                error.errorMessage,
                NotificationMessageType.Error
              );
            } else {
              ShowNotification(
                CONSTANT.message.error_unknown,
                NotificationMessageType.Error
              );
            }
          });
        break;
      default:
        break;
    }
  }
  handleShowModal(actionType) {
    this.setState({ forgotPasswordModalShow: true });
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    let returnUrl = null;
    if (this.props.location.state && this.props.location.state.returnUrl) {
      returnUrl = this.props.location.state.returnUrl;
    }
    this.props.LoginAdm(this.state, returnUrl);
  }

  render() {
    return (
      <div class="layout login-adm">
        <div class="row no-gutters">
          <div class="col-12 d-flex justify-content-center mb-5 mt-5">
            <Form className="flogin" onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  <div class="text-center text-primary">
                    <h3>Đăng Nhập</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formBasicUsername">
                    <Form.Label>Tên Đăng nhập</Form.Label>
                    <Form.Control
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                      type="text"
                      placeholder=""
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Mật Khẩu</Form.Label>
                    <Form.Control
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                      type="password"
                      placeholder=""
                    />
                  </Form.Group>

                  <Button
                    id="btn-login"
                    size="lg"
                    block
                    variant="primary"
                    type="submit"
                  >
                    Đăng Nhập{" "}
                    {this.props.admin.content
                      ? this.props.admin.content.returnUrl
                      : ""}
                  </Button>
                </Col>
              </Row>
              <Row className="mg-top-40">
                <Col>
                  <div class="text-center text-body mt-2">
                    <a
                      href="#"
                      onClick={() => {
                        this.handleShowModal();
                      }}
                    >
                      Quên Mật Khẩu?
                    </a>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <ForgotPasswordModal
          data={{
            show: this.state.forgotPasswordModalShow,
            handleCloseModal: this.handleCloseModal,
          }}
        ></ForgotPasswordModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      LoginAdm: accountAdminAction.login,
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LoginAdm);
