import {
  HELPING_ADD_RECORD_OK,
  HELPING_ADD_RECORD_ERR,
} from "./helping-add-record.action";

const InitState = {
  error: null,
  content: null,
};

export default function HelpingAddRecordReducer(state = InitState, action) {
  switch (action.type) {
    case HELPING_ADD_RECORD_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case HELPING_ADD_RECORD_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
