import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "./helping-add-record.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextEditor from "../../common/text-editor";

function HelpingAddRecord(props) {
  const [content, setContent] = useState("");
  useEffect(() => {
    if (props.helpingAddRecord && props.helpingAddRecord.content) {
      setContent(props.helpingAddRecord.content.content);
    }
  }, [props.helpingAddRecord]);

  const onSave = () => {
    props.data.handleCloseModal("save-helping", { data: content });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close-helping", { data: content });
    resetState();
  };

  const resetState = () => {
    setContent("");
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Hướng Dẫn Bổ Sung Hồ Sơ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextEditor
          value={content}
          onChange={(data) => {
            setContent(data);
          }}
          onInstance={(instance) => {}}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Đóng
        </Button>
        <Button variant="primary" onClick={onSave}>
          Lưu Thay Đổi
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  helpingAddRecord: state.helpingAddRecord,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HelpingAddRecord);
