import {
  DETAIL_USER_MANAGEMENT_OK,
  DETAIL_USER_MANAGEMENT_ERR,
} from "./user-management-detail.action";

const InitState = {
  error: null,
  content: null,
};

export default function DetailUserManagementReducer(state = InitState, action) {
  switch (action.type) {
    case DETAIL_USER_MANAGEMENT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case DETAIL_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }

    default:
      return state;
  }
}
