import {
  RESET_PASSWORD_USER_MANAGEMENT_OK,
  RESET_PASSWORD_USER_MANAGEMENT_ERR,
} from "./reset-password.action";

const InitState = {
  error: null,
  content: null,
};

export default function ResetPasswordUserManagementReducer(
  state = InitState,
  action
) {
  switch (action.type) {
    case RESET_PASSWORD_USER_MANAGEMENT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case RESET_PASSWORD_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }

    default:
      return state;
  }
}
