import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function DistrictModal(props) {
  const [name, setName] = useState(null);
  const [
    provinceAutoCompleteSelected,
    setProvinceAutoCompleteSelected,
  ] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      setName(props.data.dataInput.name);
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === props.data.dataInput.provinceId
      );
      setProvinceAutoCompleteSelected(provinceItem);
      setType(true);
    } else {
      setType(false);
    }
  }, [props]);

  const resetState = () => {
    setName(null);
    setProvinceAutoCompleteSelected(null);
    setType(null);
  };

  const handleChangeAutocomplete = (event, name, value) => {
    const provinceItem = props.lookupProvince.content.find(
      (itemProvince) => itemProvince.id === value.id
    );
    setProvinceAutoCompleteSelected(provinceItem);
  };

  const onSave = (event) => {
    event.preventDefault();
    props.data.handleCloseModal("save", {
      name: name,
      provinceId: provinceAutoCompleteSelected.id,
      type: type,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Liên Kết Web</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputPassword1">Tên</label>
            <input
              required
              name="name"
              type="text"
              class="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Tình/ Thành phố</label>
            <Autocomplete
              options={props.lookupProvince.content}
              onChange={(event, value) => {
                handleChangeAutocomplete(event, "provinceId", value);
              }}
              value={provinceAutoCompleteSelected}
              getOptionLabel={(option) => option.name}
              style={{}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({ lookupProvince: state.lookupProvince });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DistrictModal);
