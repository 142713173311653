export const CONSTANT = {
  message: {
    error_unknown:
      "Đã xảy ra lỗi không xác định, hãy liên hệ với quản trị viên.",
    changePassOk: "Đổi mật khẩu thành công",
    confirmDelete: "Bạn có chắc muốn xóa?",
    forgotPassword:
      "Đã gửi thành công, mời bạn kiểm tra email sau một vài phút!",
    warningContent: "Phần Nội Dung không thể để trống.",
    passNotMatch: "Mật khẩu nhập lại không khớp.",
  },
  label: {
    titleDocumentWait: "Hồ sơ chờ tiếp nhận",
    titleDocumentNew: "Hồ sơ mới",
    titleDocumentProcess: "Hồ sơ đang xử lý",
    titleDocumentReg: "Hồ sơ trình kí",
    titleDocumentEval: "Hồ sơ đã thẩm duyệt",
    active: "Hoạt động",
    deactive: "Tạm Dừng",
  },
};
