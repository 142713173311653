import {
  GET_POSITION_ERR,
  GET_POSITION_OK,
  DELETE_POSITION_ERR,
  DELETE_POSITION_OK,
  CREATE_POSITION_ERR,
  UPD_POSITION_ERR,
  UPD_POSITION_OK,
  GET_POSITION_LOOKUP_OK,
  GET_POSITION_LOOKUP_ERR,
} from "./position.action";

const InitState = {
  error: null,
  content: null,
};

export default function PositionAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_POSITION_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_POSITION_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_POSITION_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_POSITION_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_POSITION_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case UPD_POSITION_OK: {
      const itemParameter = action.payload;
      return {
        ...state,
        content: {
          items: state.content.items.map((item) =>
            item.id === itemParameter.id
              ? // transform the one with a matching id
                {
                  ...item,
                  name: itemParameter.name,
                  order: itemParameter.order,
                  isActive: itemParameter.isActive,
                }
              : // otherwise return original todo
                item
          ),
        },
      };
    }
    case UPD_POSITION_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}

export function PositionLookupAdmReducer(state = InitState, action) {
  switch (action.type) {
    case GET_POSITION_LOOKUP_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_POSITION_LOOKUP_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
