import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_ROLE_LOOKUP_OK = "GET_ROLE_LOOKUP_OK";
export const GET_ROLE_LOOKUP_ERR = "GET_ROLE_LOOKUP_ERR";

export const roleAction = {
  getRoleLookup,
};

function getRoleLookupOk(payload) {
  return { type: GET_ROLE_LOOKUP_OK, payload };
}
function getRoleLookupErr(payload) {
  return { type: GET_ROLE_LOOKUP_ERR, payload };
}

function getRoleLookup() {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetRoleLookup)
      .then((response) => {
        dispatch(getRoleLookupOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getRoleLookupErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
