import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function DepartmentModal(props) {
  const [name, setName] = useState(null);
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState(true);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      setName(props.data.dataInput.name);
      setOrder(props.data.dataInput.order);
      setStatus(props.data.dataInput.status);
      setType(true);
    } else {
      setType(false);
    }
  }, [props]);

  const resetState = () => {
    setName(null);
    setOrder(null);
    setStatus(true);
    setType(null);
  };

  const onSave = (event) => {
    event.preventDefault();
    props.data.handleCloseModal("save", {
      name: name,
      order: order,
      status: status,
      type: type,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Phòng Ban</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label for="exampleInputPassword1">Tên</label>
            <input
              required
              name="name"
              type="text"
              class="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Số Thứ Tự</label>
            <input
              required
              value={order}
              name="order"
              class="form-control"
              onChange={(e) => {
                setOrder(e.target.value);
              }}
              required
            ></input>
          </div>
          <div class="form-group">
            <label for="validationDefault02">Trạng Thái</label>
            <div class="form-check form-check-inline ml-1">
              <input
                class="form-check-input"
                type="checkbox"
                checked={status}
                onChange={(e) => {
                  setStatus(!status);
                }}
                name="status"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentModal);
