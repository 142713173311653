import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const RESET_PASSWORD_USER_MANAGEMENT_OK =
  "RESET_PASSWORD_USER_MANAGEMENT_OK";
export const RESET_PASSWORD_USER_MANAGEMENT_ERR =
  "RESET_PASSWORD_USER_MANAGEMENT_ERR";

export const resetPasswordAction = {
  resetPasswordUserManagement,
};

function resetPasswordUserManagementOk(payload) {
  return { type: RESET_PASSWORD_USER_MANAGEMENT_OK, payload };
}
function resetPasswordUserManagementErr(payload) {
  return { type: RESET_PASSWORD_USER_MANAGEMENT_ERR, payload };
}

function resetPasswordUserManagement(param) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.ResetPassword, param)
      .then((response) => {
        dispatch(resetPasswordUserManagementOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(resetPasswordUserManagementErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
