import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import { PrivateRoute } from "./PrivateRoute";
import history from "./common/history";
import routers from "./router";
import UrlCollect from "./common/url-collect";

import HeaderAdmin from "./components/admin/header/header-admin";
import FooterAdminView from "./components/admin/footer/footer-admin";
import LoadingWithApiQueue from "./components/loading/loading-with-queue.view";
import Sidebar from "./components/admin/sidebar/sidebar-admin";

//--- Loading
import AppLoading from "./components/loading/loading.view";

function App() {
  return (
    <div>
      <AppLoading />

      <div className="layout-container">
        <HeaderAdmin />
        <div class="wrapper bg-gay-admin">
          <Sidebar />
          {/** Router */}
          <Router history={history}>
            <Switch>
              {routers.map((prop, key) => {
                if (
                  prop.path === UrlCollect.AdmLogin ||
                  prop.path === UrlCollect.ForgotPassword
                ) {
                  return (
                    <RouteComponent
                      exact
                      layout={prop.layout}
                      component={prop.component}
                      path={prop.path}
                      key={key}
                    />
                  );
                } else {
                  return (
                    <PrivateRoute
                      exact
                      component={prop.component}
                      layout={prop.layout}
                      roles={prop.roles}
                      isAdmin={prop.isAdmin}
                      path={prop.path}
                      key={key}
                    />
                  );
                }
              })}
              {/* lap */}
            </Switch>
          </Router>
          {/** End Router */}
        </div>
        <FooterAdminView />
        <LoadingWithApiQueue />
      </div>
    </div>
  );
}

export default App;
