import { combineReducers } from "redux";
import AppReducer from "../core/app.store";
import LoadingReducer from "../redux/store/loading/loading.store";

//Admin
import SidebarReducer from "./store/admin/sidebar/sidebar.store";
import LoginAdminReducer from "./store/admin/account-adm/acc-adm.store";
import WeblinkReducer from "./store/admin/weblink/weblink.store";
import ProvinceAdmReducer from "./store/admin/province/province.store";
import DistrictAdmReducer from "./store/admin/district/district.store";
import CommuneAdmReducer from "./store/admin/commune/commune.store";
import ProjectAdmReducer from "./store/admin/project/project.store";
import DepartmentAdmReducer from "./store/admin/department/department.store";
import PositionAdmReducer from "./store/admin/position/position.store";
import NewsCategoryAdmReducer from "./store/admin/news/news.store";
import EvaluateRecordsAdmReducer from "./store/admin/evaluate-records/evaluate-records.store";
import UserReducer from "./store/admin/user/user.store";
import HelpingAddRecordReducer from "./store/admin/helping-add-record/helping-add-record.store";
import LookupCategoryReducer from "./store/admin/lookup-category/lookup-category.store";

import LookupDistrictReducer from "./store/admin/lookup-district/lookup-district.store";
import LookupProvinceReducer from "./store/admin/lookup-province/lookup-province.store";
import QualifyReducer from "./store/admin/qualify/qualify.store";

import UserManagementReducer from "./store/admin/manager-user/manager-user.store";
import RankingLookupReducer from "./store/admin/ranking/ranking.store";
import TitleLookupReducer from "./store/admin/title/title.store";
import RoleLookupReducer from "./store/admin/role/role.store";
import DepartmentLookupAdmReducer from "./store/admin/department/department-lookup.store";
import PositionLookupAdmReducer from "./store/admin/position/position-lookup.store";
import ResetPasswordUserManagementReducer from "./store/admin/manager-user/reset-password.store";
import DetailUserManagementReducer from "./store/admin/manager-user/user-management-detail.store";
import RoleReducer from "./store/admin/role/role-management.store";
import LookupScreenReducer from "./store/admin/permission/screen-lookup.store";
import LookupActionReducer from "./store/admin/permission/action-lookup.store";
import GetSecurityMatrixDetailReducer from "./store/admin/permission/get-security-matrix-detail.store";
import UpdateSecurityMatrixReducer from "./store/admin/permission/update-security-matrix.store";
import LookupDocumentTypeReducer from "./store/admin/lookup-document-type/lookup-document-type.store";
import { hash } from "immutable";

export default combineReducers({
  app: AppReducer,
  loadingState: LoadingReducer,

  // admin store
  sidebar: SidebarReducer,
  admin: LoginAdminReducer,
  weblink: WeblinkReducer,
  provinceAdm: ProvinceAdmReducer,
  districtAdm: DistrictAdmReducer,
  communeAdm: CommuneAdmReducer,
  projectAdm: ProjectAdmReducer,
  departmentAdm: DepartmentAdmReducer,
  positionAdm: PositionAdmReducer,
  newsAdm: NewsCategoryAdmReducer,
  evaluateRecords: EvaluateRecordsAdmReducer,
  user: UserReducer,
  helpingAddRecord: HelpingAddRecordReducer,
  lookupCategory: LookupCategoryReducer,
  lookupDistric: LookupDistrictReducer,
  lookupProvince: LookupProvinceReducer,
  qualify: QualifyReducer,

  userManagement: UserManagementReducer,
  rankingLookup: RankingLookupReducer,
  titleLookup: TitleLookupReducer,
  roleLookup: RoleLookupReducer,
  role: RoleReducer,
  departmentLookupAdm: DepartmentLookupAdmReducer,
  positionLookupAdm: PositionLookupAdmReducer,
  resetPasswordUserManagement: ResetPasswordUserManagementReducer,
  detailUserManagement: DetailUserManagementReducer,
  lookupScreen: LookupScreenReducer,
  lookupAction: LookupActionReducer,
  getSecurityMatrixDetail: GetSecurityMatrixDetailReducer,
  updateSecurityMatrix: UpdateSecurityMatrixReducer,

  lookupDocumentType: LookupDocumentTypeReducer,
});
