import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Service from "../../../../api/api-service";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../../redux/store/loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";

const service = new Service();

function DelegacyModal(props) {
  const [docApprStepLookup, setDocApprStepLookup] = useState([]);
  const [userDelegacyLookup, setUserDelegacyLookup] = useState([]);
  const [documentTypeLookupSelected, setDocumentTypeLookupSelected] = useState(
    null
  );
  const [docApprStepLookupSelected, setDocApprStepLookupSelected] = useState(
    null
  );
  const [userDelegacyLookupSelected, setUserDelegacyLookupSelected] = useState(
    null
  );
  const [waitingTime, setWaitingTime] = useState(null);
  const [waitingType, setWaitingType] = useState("h");
  const [apprType, setApprType] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState(null);
  const [step, setStep] = useState(null);

  const [disableAutocomplete, setDisableAutocomplete] = useState(false);

  const [status, setStatus] = useState(true);
  const [type, setType] = useState(null);

  useEffect(() => {
    setUserDelegacyLookup(props.data.dataInput.userByDepartment);
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      //define func
      /*
      const getStepDetailLocal = (id) => {
        console.log(id);
        props.ShowLoading();
        service
          .get(ApiUrl.GetStepDetail + id)
          .then((response) => {
            console.log(response);
            props.HiddenLoading();
            setWaitingTime(response.content.waitingTime);
            setWaitingType(response.content.waitingType);
            setStep(response.content.step);
          })
          .catch((error) => {
            props.HiddenLoading();
            if (error && error.errorMessage) {
              ShowNotification(
                error.errorMessage,
                NotificationMessageType.Error
              );
            } else {
              ShowNotification(
                CONSTANT.message.error_unknown,
                NotificationMessageType.Error
              );
            }
          });
      };
      */
      const getLookupDocApprStepLocal = (documentTypeId) => {
        props.ShowLoading();
        service
          .get(
            ApiUrl.GetLookupDocApprStep + "?documentTypeId=" + documentTypeId
          )
          .then((response) => {
            props.HiddenLoading();
            setDocApprStepLookup(response.content);
            const item = response.content.find(
              (item) => item.id === props.data.dataInput.docApprStepId
            );
            setDocApprStepLookupSelected(item);
            //getStepDetailLocal(props.data.dataInput.docApprStepId);
          })
          .catch((error) => {
            props.HiddenLoading();
            if (error && error.errorMessage) {
              ShowNotification(
                error.errorMessage,
                NotificationMessageType.Error
              );
            } else {
              ShowNotification(
                CONSTANT.message.error_unknown,
                NotificationMessageType.Error
              );
            }
          });
      };
      //end define func

      if (props.data.dataInput.documentTypeId) {
        const lookupDocumentTypeItem = props.lookupDocumentType.content.find(
          (item) => item.id === props.data.dataInput.documentTypeId
        );
        setDocumentTypeLookupSelected(lookupDocumentTypeItem);
        getLookupDocApprStepLocal(props.data.dataInput.documentTypeId);
      }
      if (props.data.dataInput.userDelegacyId) {
        const userDelegacyItem = props.data.dataInput.userByDepartment.find(
          (item) => item.id === props.data.dataInput.userDelegacyId
        );
        setUserDelegacyLookupSelected(userDelegacyItem);
      }

      setWaitingTime(props.data.dataInput.waitingTime);
      setWaitingType(props.data.dataInput.waitingType);
      setApprType(props.data.dataInput.apprType);
      setStartDate(
        props.data.dataInput.startDate
          ? new Date(props.data.dataInput.startDate)
          : ""
      );
      setEndDate(
        props.data.dataInput.endDate
          ? new Date(props.data.dataInput.endDate)
          : ""
      );
      setDescription(props.data.dataInput.description);
      setDisableAutocomplete(true);
      setStatus(props.data.dataInput.status);
      setType(true);
    } else {
      setType(false);
    }
  }, [props]);

  //define func
  const getStepDetail = (id) => {
    props.ShowLoading();
    service
      .get(ApiUrl.GetStepDetail + id)
      .then((response) => {
        props.HiddenLoading();
        setWaitingTime(response.content.waitingTime);
        setWaitingType(response.content.waitingType);
        setStep(response.content.step);
      })
      .catch((error) => {
        props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
  const getLookupDocApprStep = (documentTypeId) => {
    props.ShowLoading();
    service
      .get(ApiUrl.GetLookupDocApprStep + "?documentTypeId=" + documentTypeId)
      .then((response) => {
        props.HiddenLoading();
        setDocApprStepLookup(response.content);
        const item = response.content.find(
          (item) => item.id === props.data.dataInput.docApprStepId
        );
        setDocApprStepLookupSelected(item);
        if (item) {
          getStepDetail(item.id);
        }
      })
      .catch((error) => {
        props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
  //end define func

  const resetState = () => {
    //setDocumentTypeLookup([]);
    setDocApprStepLookup([]);
    setUserDelegacyLookup([]);
    setDocumentTypeLookupSelected(null);
    setDocApprStepLookupSelected(null);
    setUserDelegacyLookupSelected(null);
    setWaitingTime(null);
    setWaitingType("h");
    setApprType("1");
    setStartDate(new Date());
    setEndDate(new Date());
    setDescription(null);
    setStep(null);
    setDisableAutocomplete(false);
    setStatus(true);
    setType(null);
  };

  const onSave = (event) => {
    event.preventDefault();
    props.data.handleCloseModal("save", {
      documentTypeId: documentTypeLookupSelected.id,
      docApprStepId: docApprStepLookupSelected.id,
      step: step,
      userDelegacyId: userDelegacyLookupSelected.id,
      startDate: startDate,
      endDate: endDate,
      description: description,
      isActive: status,
      waitingTime: waitingTime,
      waitingType: waitingType,
      apprType: apprType,
      type: type,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  const handleChangeAutocomplete = (event, name, value) => {
    if (name === "documentTypeLookup" && value) {
      const lookupDocumentTypeItem = props.lookupDocumentType.content.find(
        (item) => item.id === value.id
      );
      setDocumentTypeLookupSelected(lookupDocumentTypeItem);
      getLookupDocApprStep(value.id);
    } else if (name === "docApprStepLookup" && value) {
      const item = docApprStepLookup.find((item) => item.id === value.id);
      setDocApprStepLookupSelected(item);
      getStepDetail(item.id);
    } else if (name === "userDelegacyLookup" && value) {
      const item = userDelegacyLookup.find((item) => item.id === value.id);
      setUserDelegacyLookupSelected(item);
    }
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ủy Quyền Xét Duyệt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="exampleInputPassword1">Loại chứng từ</label>
              <Autocomplete
                disabled={disableAutocomplete}
                options={props.lookupDocumentType.content}
                onChange={(event, value) => {
                  handleChangeAutocomplete(event, "documentTypeLookup", value);
                }}
                value={documentTypeLookupSelected}
                getOptionLabel={(option) => option.name}
                style={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="exampleInputPassword1">Bước xét duyệt</label>
              <Autocomplete
                disabled={disableAutocomplete}
                options={docApprStepLookup}
                onChange={(event, value) => {
                  handleChangeAutocomplete(event, "docApprStepLookup", value);
                }}
                value={docApprStepLookupSelected}
                getOptionLabel={(option) => option.name}
                style={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
            <div class="form-group col-6">
              <label for="exampleInputPassword1">Người xử lý/xét duyệt</label>
              <Autocomplete
                disabled={disableAutocomplete}
                options={userDelegacyLookup}
                onChange={(event, value) => {
                  handleChangeAutocomplete(event, "userDelegacyLookup", value);
                }}
                value={userDelegacyLookupSelected}
                getOptionLabel={(option) => option.name}
                style={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="exampleInputPassword1">Thời gian chờ duyệt</label>
              <div class="form-row">
                <div class="form-group col-6">
                  <input
                    required
                    name="title"
                    type="text"
                    class="form-control"
                    value={waitingTime}
                    onChange={(e) => {
                      setWaitingTime(e.target.value);
                    }}
                    required
                  />{" "}
                </div>
                <div class="form-group col-6">
                  <select
                    value={waitingType}
                    class="form-control w-sel ml-1 mr-1"
                    onChange={(e) => {
                      setWaitingType(e.target.value);
                    }}
                  >
                    <option value="h">Giờ</option>
                    <option value="d">Ngày</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group col-6">
              <label for="exampleInputPassword1">Loại duyệt</label>
              <select
                value={apprType}
                class="form-control w-sel ml-1 mr-1"
                onChange={(e) => {
                  setApprType(e.target.value);
                }}
              >
                <option value="1">Duyệt thủ công</option>
                <option value="2">Tự động duyệt</option>
                <option value="3">Tự động duyệt khi hết thời gian chờ</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label class="w-100" for="exampleInputPassword1">
                Duyệt từ ngày
              </label>
              <DatePicker
                className="datepic form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div class="form-group col-6">
              <label class="w-100" for="exampleInputPassword1">
                Đến ngày
              </label>
              <DatePicker
                className="datepic form-control"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="exampleInputPassword1">Ghi chú</label>
              <input
                required
                name="title"
                type="text"
                class="form-control"
                id="validationDefault01"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="validationDefault02">Kích hoạt</label>
              <div class="form-check form-check-inline ml-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={status}
                  onChange={(e) => {
                    setStatus(!status);
                  }}
                  id="defaultCheck1"
                  name="status"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  lookupDocumentType: state.lookupDocumentType,
  //userByDepartment: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(DelegacyModal);
