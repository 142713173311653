import React from "react";
import "./footer-admin.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export default class FooterAdminView extends React.Component {
  render() {
    return (
        <footer>
          <div className="copyright">
            <div className="container">
              © Bản quyền thuộc về Công Ty ACUD GROUP
            </div>
          </div>
        </footer>
    );
  }
}
