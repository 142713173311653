import {
  GET_LIST_ROLE_OK,
  GET_LIST_ROLE_ERR,
  CREATE_ROLE_OK,
  CREATE_ROLE_ERR,
  UPDATE_ROLE_OK,
  UPDATE_ROLE_ERR,
  DELETE_ROLE_OK,
  DELETE_ROLE_ERR,
} from "./role-management.action";

const InitState = {
  error: null,
  content: null,
};

export default function RoleReducer(state = InitState, action) {
  switch (action.type) {
    case GET_LIST_ROLE_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LIST_ROLE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_ROLE_OK: {
      const id = action.payload;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_ROLE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_ROLE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case UPDATE_ROLE_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
