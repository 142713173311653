import {
  GET_QUALIFY_OK,
  GET_QUALIFY_ERR,
  DELETE_QUALIFY_OK,
  DELETE_QUALIFY_ERR,
  CREATE_QUALIFY_ERR,
} from "./qualify.action";

const InitState = {
  error: null,
  content: null,
};

export default function QualifyReducer(state = InitState, action) {
  switch (action.type) {
    case GET_QUALIFY_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_QUALIFY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case DELETE_QUALIFY_OK: {
      const id = action.payload.Id;
      return {
        ...state,
        error: null,
        content: {
          items: state.content.items.filter((item) => item.id !== id),
        },
      };
    }
    case DELETE_QUALIFY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    case CREATE_QUALIFY_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
