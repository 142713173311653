/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import {
  HiddenLoading,
  ShowLoading,
} from "../../../redux/store/loading/loading.store";
import apiEnvironment from "../../../api/api-config";
import { CONSTANT } from "../../../constants/constant";

import "./account.scss";

const service = new Service();
const urlImg = apiEnvironment.api;

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      fullName: "",
      email: "",
      roleName: "",
      dateOfBirth: "",
      sex: false,
      address: "",
      avatar: "",
      phoneNumber: "",
      //passCurrent: "",
      //passNew: "",
      //avatarNew: null,
      //labelFile: "Chọn Ảnh...",
      //check btn click
      btnClick: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    this.props.ShowLoading();
    service
      .get(ApiUrl.GetUserAccountDetail)
      .then((response) => {
        this.setState({
          ...this.state,
          id: response.content.id,
          fullName: response.content.fullName,
          email: response.content.email,
          roleName: response.content.roleName,
          dateOfBirth: response.content.dateOfBirth,
          sex: response.content.sex,
          address: response.content.address,
          avatar: response.content.avatar,
          phoneNumber: response.content.phoneNumber,
        });
        this.props.HiddenLoading();
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  }
  btnSbtClick(btnName) {
    this.setState({
      ...this.state,
      btnClick: btnName,
    });
  }
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    /*
    const data = {
      currentPassword: this.state.passCurrent,
      newPassword: this.state.passNew,
    };
    this.props.ShowLoading();
    service
      .post(ApiUrl.ChangePassword, data)
      .then((response) => {
        this.props.HiddenLoading();
        ShowNotification(
          CONSTANT.message.changePassOk,
          NotificationMessageType.Success
        );
      })
      .catch((error) => {
        this.props.HiddenLoading();
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
      */
  }
  render() {
    return (
      <div class="layout-container pd15 weblinkl">
        {/**Row 1 */}
        <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
          Thông tin tài khoản
        </div>
        <div class="row no-gutters vertical-center bg-white mg-botton2">
          <div class="form-row w-100 pt-3">
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Ảnh đại diện</label>
              <div class="col-auto">
                <img
                  src={urlImg + this.state.avatar}
                  alt=""
                  class="img-max-news-l"
                ></img>
              </div>
            </div>
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Họ và tên</label>
              <input
                readOnly
                name="fullName"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.fullName}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div class="form-row w-100">
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Chức vụ</label>
              <input
                readOnly
                name="roleName"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.roleName}
                onChange={this.handleChange}
                required
              />
            </div>
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Ngày sinh</label>
              <input
                readOnly
                name="dateOfBirth"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.dateOfBirth}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div class="form-row w-100">
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Giới tính</label>
              <input
                readOnly
                name="sex"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.sex}
                onChange={this.handleChange}
                required
              />
            </div>
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Địa chỉ</label>
              <input
                readOnly
                name="address"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.address}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div class="form-row w-100">
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault02">Email</label>
              <input
                readOnly
                name="email"
                type="text"
                class="form-control"
                id="validationDefault02"
                placeholder=""
                value={this.state.liemailnk}
                onChange={this.handleChange}
                required
              />
            </div>
            <div class="col-6 mb-3 pl-3">
              <label for="validationDefault01">Số điện thoại</label>
              <input
                readOnly
                name="phoneNumber"
                type="text"
                class="form-control"
                id="validationDefault01"
                placeholder=""
                value={this.state.phoneNumber}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
        </div>

        {/**Row 2 */}
        {/*}
        <form onSubmit={this.handleSubmit}>
          <div class="row no-gutters vertical-center h-50-px clTitle bg-white mg-botton">
            Đổi mật khẩu
          </div>
          <div class="row no-gutters vertical-center bg-white mg-botton2">
            <div class="form-row w-100 pt-3">
              <div class="col-6 pl-3">
                <label for="validationDefault01">Mật khẩu hiện tại</label>
                <input
                  name="passCurrent"
                  type="password"
                  class="form-control"
                  id="validationDefault01"
                  placeholder=""
                  value={this.state.passCurrent}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div class="form-row w-100 pt-3">
              <div class="col-6 mb-3 pl-3">
                <label for="validationDefault01">Mật khẩu mới</label>
                <input
                  name="passNew"
                  type="password"
                  class="form-control"
                  id="validationDefault01"
                  value={this.state.passNew}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div class="row no-gutters vertical-center mg-botton2">
            <div class="col d-flex justify-content-center">
              <button class="btn btn-primary mr-2" name="update" type="submit">
                Cập Nhật
              </button>
            </div>
          </div>
        </form>
      {*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowLoading: ShowLoading,
      HiddenLoading: HiddenLoading,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Account);
