import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import { ApiUrl } from "../../../../api/api-url";
import Service from "../../../../api/api-service";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_COMMUNE_OK = "GET_COMMUNE_OK";
export const GET_COMMUNE_ERR = "GET_COMMUNE_ERR";
export const DELETE_COMMUNE_OK = "DELETE_COMMUNE_OK";
export const DELETE_COMMUNE_ERR = "DELETE_COMMUNE_ERR";
export const CREATE_COMMUNE_ERR = "CREATE_COMMUNE_ERR";
export const UPD_COMMUNE_ERR = "UPD_COMMUNE_ERR";
export const UPD_COMMUNE_OK = "UPD_COMMUNE_OK";

export const communeAction = {
  getCommune,
  deleteCommune,
  createCommune,
  updCommune,
};
function updCommuneOk(payload) {
  return { type: UPD_COMMUNE_OK, payload };
}
function updCommuneErr(payload) {
  return { type: UPD_COMMUNE_ERR, payload };
}
function createCommuneErr(payload) {
  return { type: CREATE_COMMUNE_ERR, payload };
}
function deleteCommuneOk(payload) {
  return { type: DELETE_COMMUNE_OK, payload };
}
function deleteCommuneErr(payload) {
  return { type: DELETE_COMMUNE_ERR, payload };
}
function getCommuneOk(payload) {
  return { type: GET_COMMUNE_OK, payload };
}
function getCommuneErr(payload) {
  return { type: GET_COMMUNE_ERR, payload };
}

function updCommune(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(
        ApiUrl.UpdateCommuneAdm,
        //data
        {
          id: data.id,
          name: data.name,
          administrativeUnitCode: data.administrativeUnitCode,
          districtId: data.districtId,
        }
      )
      .then((response) => {
        dispatch(updCommuneOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(updCommuneErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createCommune(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateCommuneAdm, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(getCommune());
      })
      .catch((error) => {
        dispatch(createCommuneErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteCommune(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteCommuneAdm + "?Id=" + data.Id)
      .then((response) => {
        dispatch(deleteCommuneOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteCommuneErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function getCommune(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListCommuneAdm + "?pageIndex=1&pageSize=100000")
      .then((response) => {
        dispatch(getCommuneOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getCommuneErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
