import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function CommuneModal(props) {
  const [
    districtAutoCompleteSelected,
    setDistrictAutoCompleteSelected,
  ] = useState(null);
  const [
    provinceAutoCompleteSelected,
    setProvinceAutoCompleteSelected,
  ] = useState(null);
  const [districtLookupByProvince, setDistrictLookupByProvince] = useState([]);
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.dataInput &&
      props.data.dataInput.type &&
      props.data.show
    ) {
      setName(props.data.dataInput.name);
      setType(true);
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === props.data.dataInput.provinceId
      );
      const districtItem = props.lookupDistric.content.items.find(
        (itemDistrict) => itemDistrict.id === props.data.dataInput.districtId
      );
      let districtLookupByProvinceFind = props.lookupDistric.content.items.filter(
        (item) => {
          return item.provinceId === (provinceItem ? provinceItem.id : 0);
        }
      );
      setProvinceAutoCompleteSelected(provinceItem);
      setDistrictLookupByProvince(districtLookupByProvinceFind);
      setDistrictAutoCompleteSelected(districtItem);
    } else {
      setType(false);
    }
  }, [props]);

  const resetState = () => {
    setDistrictAutoCompleteSelected(null);
    setProvinceAutoCompleteSelected(null);
    setDistrictLookupByProvince([]);
    setName(null);
    setType(null);
  };

  const onSave = (event) => {
    event.preventDefault();
    props.data.handleCloseModal("save", {
      districtId: districtAutoCompleteSelected.id,
      provinceId: provinceAutoCompleteSelected.id,
      provinceName: provinceAutoCompleteSelected.name,
      districtName: districtAutoCompleteSelected.name,
      name: name,
      type: type,
    });
    resetState();
  };
  const onClose = () => {
    props.data.handleCloseModal("close", {});
    resetState();
  };

  const handleChangeAutocomplete = (event, name, value) => {
    if (name === "districtId") {
      const districtItem = props.lookupDistric.content.items.find(
        (itemDistrict) => itemDistrict.id === value.id
      );
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === districtItem.provinceId
      );
      setDistrictAutoCompleteSelected(districtItem);
      setProvinceAutoCompleteSelected(provinceItem);
    } else {
      const provinceItem = props.lookupProvince.content.find(
        (itemProvince) => itemProvince.id === value.id
      );
      let districtLookupByProvinceFind = props.lookupDistric.content.items.filter(
        (item) => {
          return item.provinceId === provinceItem.id;
        }
      );
      setProvinceAutoCompleteSelected(provinceItem);
      setDistrictLookupByProvince(districtLookupByProvinceFind);
    }
  };

  return (
    <Modal centered size="lg" show={props.data.show} onHide={onClose}>
      <form
        onSubmit={(e) => {
          onSave(e);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Phường Xã</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="exampleInputPassword1">Tên</label>
              <input
                required
                name="name"
                type="text"
                class="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="exampleInputPassword1">Tỉnh/ Thành</label>
              <Autocomplete
                options={props.lookupProvince.content}
                onChange={(event, value) => {
                  handleChangeAutocomplete(event, "provinceId", value);
                }}
                value={provinceAutoCompleteSelected}
                getOptionLabel={(option) => option.name}
                style={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <label for="exampleInputPassword1">Quận/ Huyện</label>
              <Autocomplete
                options={districtLookupByProvince}
                onChange={(event, value) => {
                  handleChangeAutocomplete(event, "districtId", value);
                }}
                value={districtAutoCompleteSelected}
                getOptionLabel={(option) => option.name}
                style={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Đóng
          </Button>
          <Button type="submit" variant="primary">
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  lookupProvince: state.lookupProvince,
  lookupDistric: state.lookupDistric,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CommuneModal);
