import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import { CONSTANT } from "../../../../constants/constant";
const service = new Service();

export const GET_LIST_ROLE_OK = "GET_LIST_ROLE_OK";
export const GET_LIST_ROLE_ERR = "GET_LIST_ROLE_ERR";
export const CREATE_ROLE_OK = "CREATE_ROLE_OK";
export const CREATE_ROLE_ERR = "CREATE_ROLE_ERR";
export const UPDATE_ROLE_OK = "UPDATE_ROLE_OK";
export const UPDATE_ROLE_ERR = "UPDATE_ROLE_ERR";
export const DELETE_ROLE_OK = "DELETE_ROLE_OK";
export const DELETE_ROLE_ERR = "DELETE_ROLE_ERR";

export const roleManagement = {
  getListRole,
  createRole,
  updateRole,
  deleteRole,
};

function getListRoleOk(payload) {
  return { type: GET_LIST_ROLE_OK, payload };
}
function getListRoleErr(payload) {
  return { type: GET_LIST_ROLE_ERR, payload };
}

function createRoleOk(payload) {
  return { type: CREATE_ROLE_OK, payload };
}
function createRoleErr(payload) {
  return { type: CREATE_ROLE_ERR, payload };
}

function updateRoleOk(payload) {
  return { type: UPDATE_ROLE_OK, payload };
}
function updateRoleErr(payload) {
  return { type: UPDATE_ROLE_ERR, payload };
}

function deleteRoleOk(payload) {
  return { type: DELETE_ROLE_OK, payload };
}
function deleteRoleErr(payload) {
  return { type: DELETE_ROLE_ERR, payload };
}

function getListRole(param) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetListRole, param)
      .then((response) => {
        dispatch(getListRoleOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getListRoleErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function createRole(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.CreateRole, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getListRole(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(createRoleErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function updateRole(data) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.UpdateRole, data)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        dispatch(
          getListRole(
            "pageIndex=1&pageSize=10000000&sortExpression=modifiedDate+desc"
          )
        );
      })
      .catch((error) => {
        dispatch(updateRoleErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}

function deleteRole(data) {
  var param = "id=" + data;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .post(ApiUrl.DeleteRole, param)
      .then((response) => {
        dispatch(deleteRoleOk(data));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(deleteRoleErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        if (error && error.errorMessage) {
          ShowNotification(error.errorMessage, NotificationMessageType.Error);
        } else {
          ShowNotification(
            CONSTANT.message.error_unknown,
            NotificationMessageType.Error
          );
        }
      });
  };
}
