import {
  GET_USER_MANAGEMENT_OK,
  GET_USER_MANAGEMENT_ERR,
  CREATE_USER_MANAGEMENT_OK,
  CREATE_USER_MANAGEMENT_ERR,
  UPDATE_USER_MANAGEMENT_OK,
  UPDATE_USER_MANAGEMENT_ERR,
  ACTIVE_USER_MANAGEMENT_OK,
  ACTIVE_USER_MANAGEMENT_ERR,
  DEACTIVE_USER_MANAGEMENT_OK,
  DEACTIVE_USER_MANAGEMENT_ERR,
} from "./manager-user.action";

const InitState = {
  error: null,
  content: null,
};

export default function UserManagementReducer(state = InitState, action) {
  switch (action.type) {
    case GET_USER_MANAGEMENT_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    case CREATE_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.errorMessage,
        content: state.content,
      };
    }
    case UPDATE_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.message,
        content: state.content,
      };
    }
    // case ACTIVE_USER_MANAGEMENT_OK: {
    //   const id = action.payload;
    //   state.content.items.map((item) => {
    //     if (item.id === id) {
    //       item.status = true;
    //     }
    //   });
    //   return {
    //     ...state,
    //     error: null,
    //     content: state.content,
    //   };
    // }
    case ACTIVE_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    // case DEACTIVE_USER_MANAGEMENT_OK: {
    //   const id = action.payload;
    //   state.content.items.map((item) => {
    //     if (item.id === id) {
    //       item.status = false;
    //     }
    //   });
    //   return {
    //     ...state,
    //     error: null,
    //     content: state.content,
    //   };
    // }
    case DEACTIVE_USER_MANAGEMENT_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: state.content,
      };
    }
    default:
      return state;
  }
}
